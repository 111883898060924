<template>
    <div class="modal fade" id="support_modal" tabindex="-1" role="dialog" aria-labelledby="support_modal_title" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title mr-2" id="support_modal_title">Bolt On Technology® Support | Contact Us</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <div class="row">
                        <div class="col">
                            <button @click="toggle.showEmailForm = !toggle.showEmailForm" type="button" class="btn btn-alpha_secondary btn-lg btn-block">Email <i class="fas fa-at"></i></button>
                        </div>
                        <div class="col">
                            <a href="tel:866-500-6161" class="btn btn-alpha_primary btn-lg btn-block">(866) 500-6161 <i class="fas fa-phone"></i></a>
                        </div>
                    </div>

                    <transition name="expand">
                        <div v-if="toggle.showEmailForm" class="mt-4">
                            <form @submit.prevent="sendSupportEmail" id="form_send_support_email">
                                <!-- Email -->
                                <div v-if="!userSession.hasVerifiedEmail" class="input-group input-group-sm mb-2">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Email Address</span>
                                    </div>
                                    <input
                                        v-model="supportForm.email"
                                        type="email"
                                        name="email"
                                        class="form-control"
                                        required="true"
                                    />
                                </div>

                                <!-- Support Type -->
                                <div class="input-group input-group-sm mb-2">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Support Type</span>
                                    </div>
                                    <select
                                        v-model="supportForm.supportType"
                                        class="form-control"
                                    >
                                        <option
                                            v-for="(type, index) in supportFormOptions.supportTypes"
                                            :key="index"
                                            :value="type"
                                        >{{ type }}</option>
                                    </select>
                                </div>

                                <!-- Subject -->
                                <div class="input-group input-group-sm mb-2">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Subject</span>
                                    </div>
                                    <input
                                        v-model="supportForm.subject"
                                        type="text"
                                        name="subject"
                                        class="form-control"
                                        :placeholder="supportForm.subject"
                                        :maxlength="supportFormOptions.maxSubjectLength"
                                        required
                                    />
                                </div>

                                <!-- Message -->
                                <el-input
                                    v-model="supportForm.message"
                                    class="mb-2"
                                    type="textarea"
                                    :autosize="{ minRows: 2, maxRows: 4}"
                                    placeholder="Type message"
                                    required="true"
                                ></el-input>

                                <!-- Attached Files -->
                                <div v-if="uppyUploadedFileUrls && uppyUploadedFileUrls.length" class="row mb-2">
                                    <div class="col d-flex flex-wrap mb-2 mb-sm-0">
                                        <div v-for="(file, index) in uppyUploadedFileUrls" :key="index" class="attached-file">
                                            <a :href="file.url" target="_blank">{{ file.name }}</a>
                                            <button @click="removeUppyFileUrl(file)" type="button" class="text-danger ml-1">
                                                <i class="fas fa-times-circle"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <!-- Attach Files -->
                                <button
                                    v-if="!isUppyOpen"
                                    @click="isUppyOpen = !isUppyOpen"
                                    id="uppy-trigger"
                                    class="add-attachment-button badge badge-alpha_neutral--shift mr-2 mb-0 border-0 mb-2"
                                    type="button"
                                ><i class="fas fa-file"></i> Attach Files</button>

                                <transition name="expand">
                                    <div v-show="isUppyOpen">
                                        <dashboard :uppy="uppy" :plugins="[]" :props="uppyDashboardProps" />
                                    </div>
                                </transition>
                            </form>
                        </div>
                    </transition>
                </div>
                <div class="modal-footer">
                    <!-- Submit -->
                    <button form="form_send_support_email" class="btn btn-alpha_primary" :disabled="isLoading.sendSupportEmail">Send</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { Dashboard } from '@uppy/vue';
import { mapState } from "vuex";

export default {
    name: "ModalSupport",

    components: {
        Dashboard,
    },

    data() {
        return {
            isLoading: {
                sendSupportEmail: false,
            },

            isUppyOpen: false,

            supportForm: {
                email: '',
                message: '',
                subject: '',
                supportType: '',
            },

            supportFormOptions: {
                maxSubjectLength: 50,
                supportTypes: [
                    'General Support',
                    'Technical Support',
                    'Billing Support',
                    'Other',
                ],
            },

            toggle: {
                showEmailForm: false,
            },

            uppyDashboardProps: {
                doneButtonHandler: () => {
                    this.uppy.reset();
                    this.isUppyOpen = false;
                },
                height: 400,
                showRemoveButtonAfterComplete: false,
                showLinkToFileUploadResult: false,
                theme: 'light',
            },

            uppyUploadedFileUrls: [],
        }
    },

    computed: {
        ...mapState('userSession', {
            userSession: 'userSession',
        }),

        // Uppy Instance
        uppy: function() {
            return new Uppy({
                restrictions: {
                    allowedFileTypes: ['image/*', 'video/*'],
                    maxFileSize: 1000000 * 1000, // Megabytes
                    maxNumberOfFiles: 5,
                    maxTotalFileSize: 1000000 * 1000, // Megabytes
                },
            }).use(AwsS3Multipart, {
                limit: 4,
                companionUrl: '/',
            }).on('complete', (result) => {
                this.addUppyFileUrls(result.successful);
            });
        },
    },

    beforeDestroy () {
        // Delete unsent files and close uppy
        try {
            // this.fileUrls.forEach(fileUrl => {
            //     this.deleteFileFromS3(fileUrl.key);
            // });
            this.uppy.close();
        } catch (error) {
            console.error(error);
        }
    },

    created() {
        this.resetSupportForm();
    },

    methods: {
        areUppyFilesPending() {
            const files = this.uppy.getFiles();
            let areFilesPending = false;

            files.forEach(file => {
                if (!file.progress.uploadComplete) {
                    areFilesPending = true;
                }
            });

            return areFilesPending;
        },

        showModal() {
            $('#support_modal').modal('show');
        },

        hideModal() {
            $('#support_modal').modal('hide');
        },

        resetSupportForm() {
            this.supportForm.email = this.userSession?.userInfo?.email ?? '';
            this.supportForm.message = '';
            this.supportForm.subject = '';
            this.supportForm.supportType = 'General Support';
            this.uppyUploadedFileUrls = [];
        },

        async sendSupportEmail() {
            let shouldContinue = true;

            if (this.areUppyFilesPending()) {
                await this.$confirm('You have pending files that have not been uploaded yet. Continue without attaching files?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    //
                }).catch(() => {
                    shouldContinue = false;
                    this.$message({
                        type: 'info',
                        message: 'Cancelled sending message.'
                    });
                });
            }

            if (!shouldContinue) {
                return;
            }

            let parameters = {
                email: this.supportForm.email,
                fileUrls: this.uppyUploadedFileUrls.map(file => file.url),
                message: this.supportForm.message,
                subject: this.supportForm.subject,
                supportType: this.supportForm.supportType,
            };

            if (!parameters.email) {
                parameters.email = this.userSession?.userInfo?.email ?? '';
            }

            this.isLoading.sendSupportEmail = true;

            axios
                .post(route('api.email-support', { selectedDealer: this.selectedDealerId }), parameters)
                .then(response => {
                    if (response.data.success) {
                        // Hide & clear the support form
                        this.toggle.showEmailForm = false;
                        this.resetSupportForm();

                        try {
                            this.uppy.reset();
                        } catch (error) {
                            console.error(error);
                        }

                        // Inform of success
                        this.$message({
                            type: 'success',
                            message: 'Message sent successfully. We will respond as soon as possible.'
                        });
                    }
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }

                    this.$message({
                        type: 'error',
                        message: 'Your message was unable to be sent at this time.'
                    });
                })
                .finally(() => {
                    this.isLoading.sendSupportEmail = false;
                });
        },

        // Add file URLs to uppyUploadedFileUrls array
        addUppyFileUrls(files) {
            const fileUrls = [...this.uppyUploadedFileUrls];

            files.forEach(file => {
                fileUrls.push({
                    bucket: file.response.body.bucket,
                    fileGuid: file.response.body.metadata['file-guid'] ?? null,
                    id: file.id,
                    key: file.s3Multipart.key,
                    name: file.name,
                    url: file.uploadURL,
                });
            });

            this.uppyUploadedFileUrls = fileUrls;
        },

        // Remove file URLs from uppyUploadedFileUrls array
        removeUppyFileUrl(fileToRemove) {
            const fileUrls = this.uppyUploadedFileUrls.filter(file => {
                if(file.url === fileToRemove.url) {
                    return false;
                }

                return true;
            });

            this.uppyUploadedFileUrls = fileUrls;

            try {
                this.uppy.removeFile(fileToRemove.id);
            } catch (error) {
                console.error(error);
            }

            this.deleteFileFromS3(fileToRemove.key);
        },

        // Delete file from S3 bucket
        deleteFileFromS3(key) {
            axios
                .post(route('s3.multipart.deleteUploaded'), { key: key })
                .then(response => {
                    console.info(`Deleted the file ${key} successfully from S3.`);
                })
                .catch(error => {
                    console.error(`The file ${key} was unable to be deleted from S3.`);
                });
        },
    }

}
</script>

<style lang="scss" scoped>
// Vue transition classes
.expand-enter-active, .expand-leave-active {
  max-height: 500px;
  overflow: hidden;
  transition: max-height 1s;
}
.expand-enter, .expand-leave-to {
  max-height: 0;
}

.attached-file {
    background: var(--alpha_bg--shift);
    border-radius: 5px;
    margin: 0 2px 2px 0;
    padding: 2px 7px;

    button {
        background: none;
        border: none;
        padding: 0;
    }
}
</style>
