<template>
    <div
        class="chat-message"
        :class="direction === 'incoming' ? 'chat-message--incoming' : 'chat-message--outgoing'"
    >
        <div class="chat-message__user-icon" v-tooltip:[tooltipPlacement] :title="sender.displayName">
            {{ sender.initials }}
        </div>
        <div class="chat-message__bubble-wrapper">
            <div class="chat-message__bubble">
                <span v-if="message.message" v-html="convertUrlsToHtml({text: stripSlashes(message.message), target: '_blank', style: 'color: inherit'})"></span>
                <small v-else><em>(No data to display)</em></small>
            </div>
        </div>
        <div
            class="chat-message__caption"
        >
            <i class="icon mr-1">send</i>
            Sent By {{ sender.displayName }} 
            • {{ $moment.utc(message.date).local().format('MM/DD/YY h:mm A') }}
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { convertUrlsToHtml, stripSlashes } from '@/filters/stringFormats';
import { generalMixin } from '@/mixins/generalMixin';

export default {
    mixins: [generalMixin],

    props: {
        message: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            //
        }
    },

    computed: {
        ...mapState('userSession', {
            userSession: 'userSession',
        }),

        direction() {
            return this.message.created_by === this.userSession?.externalUserId ? 'outgoing' : 'incoming';
        },

        sender() {
            const foundASM = this.$_generalMixin_getASMByIdOrNumber(this.message.created_by);

            const senderInfo = {
                initials: '',
                displayName: '',
            };

            senderInfo.initials = foundASM && (foundASM.first_name || foundASM.last_name) ? foundASM.first_name.charAt(0) + foundASM.last_name.charAt(0) : 'B';
            senderInfo.displayName = foundASM && (foundASM.first_name || foundASM.last_name) ? `${foundASM.first_name} ${foundASM.last_name}` : 'Bolt On Tech';

            return senderInfo;
        },

        tooltipPlacement() {
            return this.direction === 'incoming' ? 'right' : 'left';
        },
    },

    methods: {
        /**
         * Imported methods
         */
        convertUrlsToHtml,
        stripSlashes,
    },
}
</script>
