<template>
<div ref="toyotaIntervalTrack" id="toyota_interval_track">
    <h2 class="font-20 mb-3">Toyota Care Progress</h2>
    <canvas v-if="codesFiltered.length" ref="toyotaIntervalTrackCanvas" />
</div>
</template>

<script>
export default {
    name: 'ToyotaCareIntervalGraphic',

    props: {
        codes: {
            type: Array,
            required: true
        },

        mileage: {
            type: Number,
            required: true
        },
    },

    data() {
        return {
            alphaBg: 'white',
            alphaBorder: 'black',
            alphaDanger: 'red',
            alphaNeutral: 'grey',
            alphaSuccess: 'green',
            alphaText: 'black',
            bodyStyle: getComputedStyle(document.body),
            canvasHeight: 47,
            canvasWidth: 200,
            circleWidth: 20,
            ctx: null,
            lineY: 35,
            margin: 20,

            toyotaCareCodes: [
                {
                    codes: ['05KCON', '05KFCV', '05KGRC', '05KSYN'],
                    mileage: 5000,
                    name: '5K'
                },
                {
                    codes: ['07KT86'],
                    mileage: 7500,
                    name: '7K'
                },
                {
                    codes: ['10KCON', '10KFCV', '10KGRC', '10KSUP', '10KSYN'],
                    mileage: 10000,
                    name: '10K'
                },
                {
                    codes: ['15KCON', '15KFCV', '15KGRC', '15KSYN', '15KT86'],
                    mileage: 15000,
                    name: '15K'
                },
                {
                    codes: ['20KCON', '20KFCV', '20KGRC', '20KSUP', '20KSYN'],
                    mileage: 20000,
                    name: '20K'
                },
                {
                    codes: ['22KT86'],
                    mileage: 22500,
                    name: '22.5K'
                },
                {
                    codes: ['25KCON', '25KFCV', '25KGRC', '25KSYN'],
                    mileage: 25000,
                    name: '25K'
                },
                {
                    codes: ['30KFCV'],
                    mileage: 30000,
                    name: '30K'
                },
                {
                    codes: ['35KFCV'],
                    mileage: 35000,
                    name: '35K'
                },
            ],
        }
    },

    computed: {
        codesFiltered() {
            const lastCodes = this.codes.slice(-2);
            const codesFiltered = [];

            lastCodes.forEach((code) => {
                const intervalOption = this.toyotaCareCodes.find(
                    option => option.codes.includes(code)
                );

                if (!intervalOption) {
                    return;
                }

                codesFiltered.push({
                    name: intervalOption.name,
                    completed: true,
                    mileage: intervalOption.mileage,
                });
            });

            // Add the next interval
            const nextInterval = this.toyotaCareCodes.find(
                option => codesFiltered.length
                    && option.mileage > codesFiltered.at(-1).mileage
            );

            if (nextInterval) {
                codesFiltered.push({
                    name: nextInterval.name,
                    completed: false,
                    mileage: nextInterval.mileage,
                });
            }

            // If only 1 interval exists, add previous interval
            if (codesFiltered.length === 1) {
                // Find index of the interval in the interval options
                const intervalIndex = this.toyotaCareCodes.findIndex(
                    option => option.codes.includes(this.codes.at(-1))
                );

                if (intervalIndex && this.toyotaCareCodes[intervalIndex - 2]) {
                    codesFiltered.unshift({
                        name: this.toyotaCareCodes[intervalIndex - 2].name,
                        completed: false,
                        mileage: this.toyotaCareCodes[intervalIndex - 2].mileage,
                    });
                }
            }

            return codesFiltered;
        },
    },

    mounted() {
        this.alphaBg = this.bodyStyle.getPropertyValue('--alpha_bg');
        this.alphaBorder = this.bodyStyle.getPropertyValue('--alpha_border');
        this.alphaDanger = this.bodyStyle.getPropertyValue('--alpha_danger');
        this.alphaNeutral = this.bodyStyle.getPropertyValue('--alpha_neutral');
        this.alphaSuccess = this.bodyStyle.getPropertyValue('--alpha_success');
        this.alphaText = this.bodyStyle.getPropertyValue('--alpha_text');
        this.canvasWidth = this.$refs.toyotaIntervalTrack.offsetWidth;
        this.ctx = this.$refs.toyotaIntervalTrackCanvas.getContext('2d');

        document.fonts.load('900 14px "Font Awesome 6 Pro"').then(() => {
            if (this.codesFiltered.length) {
                this.renderGraphic();
            }
        });
    },

    methods: {
        renderGraphic() {
            this.$refs.toyotaIntervalTrackCanvas.height = this.canvasHeight;
            this.$refs.toyotaIntervalTrackCanvas.width = this.canvasWidth;

            // Timeline
            this.ctx.beginPath();
            this.ctx.moveTo(0, this.lineY);
            this.ctx.lineTo(this.canvasWidth, this.lineY);
            this.ctx.strokeStyle = this.alphaBorder;
            this.ctx.stroke();

            // Milestone Markers
            this.codesFiltered.forEach((code, index) => {
                this.generateMilestoneMarker(
                    code,
                    index + 1 !== this.codesFiltered.length
                        ? (index * (this.circleWidth + this.margin)) + this.margin
                        : this.canvasWidth - (this.circleWidth + this.margin),
                    this.lineY - (this.circleWidth / 2)
                );
            });

            // Current Milestone Progress (Car)
            const trackStart = (this.codesFiltered.length - 1) * (this.circleWidth + this.margin) + this.margin;
            const trackLength = this.canvasWidth - (this.margin * 2) - ((this.circleWidth + this.margin) * this.codesFiltered.length);
            const nextMilestone = this.codesFiltered.at(-1).mileage - this.codesFiltered.at(-2).mileage;
            const currentMilestoneMileage = this.getMinMax(
                this.mileage - this.codesFiltered.at(-2).mileage,
                0,
                nextMilestone
            );
            const nextMilestonePercentage = (currentMilestoneMileage / nextMilestone);
            const carPosition = this.getMinMax(
                trackStart + (trackLength * nextMilestonePercentage),
                trackStart,
                trackLength + trackStart
            );

            this.ctx.font = 'bold 14px "Font Awesome 6 Pro"';
            this.ctx.fillStyle = this.alphaText;
            this.ctx.textAlign = 'center';
            this.ctx.fillText('\uf5e4', carPosition, this.lineY + 5);
        },

        getMinMax(number, min, max) {
            return Math.min(
                Math.max(
                    parseInt(
                        number
                    ),
                    min
                ),
                max
            )
        },

        generateMilestoneMarker(interval, x = 0, y = 0) {
            const r = this.circleWidth / 2;
            x = x + r;
            y = y + r;

            // Label
            this.ctx.font = 'bold 12px verdana';
            this.ctx.fillStyle = this.alphaText;
            this.ctx.textAlign = 'center';
            this.ctx.fillText(interval.name, x, y - 25);

            // Circle
            this.ctx.beginPath();
            this.ctx.arc(x, y, r, 0, Math.PI * 2);
            this.ctx.fillStyle = interval.completed
                ? this.alphaSuccess
                : (
                    !interval.completed
                    && this.mileage > interval.mileage
                        ? this.alphaDanger : this.alphaNeutral
                );
            this.ctx.fill();
            this.ctx.lineWidth = 2;
            this.ctx.strokeStyle = this.alphaBg;
            this.ctx.stroke();

            // Check mark
            if (interval.completed) {
                this.ctx.beginPath();
                this.ctx.moveTo(x - 5, y - 1);
                this.ctx.lineTo(x - 2, y + 3);
                this.ctx.lineTo(x + 5, y - 4);
                this.ctx.lineWidth = 3;
                this.ctx.strokeStyle = '#fff';
                this.ctx.stroke();
            }

            // X mark
            if (
                !interval.completed
                && this.mileage > interval.mileage
            ) {
                this.ctx.beginPath();
                this.ctx.moveTo(x - 4, y - 4);
                this.ctx.lineTo(x + 4, y + 4);
                this.ctx.lineWidth = 3;
                this.ctx.strokeStyle = '#fff';
                this.ctx.stroke();

                this.ctx.beginPath();
                this.ctx.moveTo(x + 4, y - 4);
                this.ctx.lineTo(x - 4, y + 4);
                this.ctx.lineWidth = 3;
                this.ctx.strokeStyle = '#fff';
                this.ctx.stroke();
            }
        },
    },
}
</script>

<style lang="scss" scoped>
    #toyota_interval_track {
        width: 100%;
    }
</style>
