<template>
    <div class="row">
        <div class="result-info-wrapper py-3 scrolling-column order-2 order-md-1">

            <div class="col h-100 d-flex flex-column flex-grow-1 flex-shrink-1">
                <div class="row sm-gutters mb-1 flex-shrink-0">
                    <!-- Vehicle Select -->
                    <div class="col-12 col-sm-6 col-lg-6 col-xl-6 d-flex mb-2">
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                                <span class="input-group-text">Vehicle</span>
                            </div>
                            <el-select
                                class="select-blue hide-overflow-tags w-100"
                                v-model="selectedVehicleId"
                                size="mini"
                                placeholder="Select"
                            >
                                <el-option
                                    v-for="(car, index) in [{ year: '', v_make: 'All', v_model: 'Vehicles', id: null }, ...filteredCars]"
                                    :key="index"
                                    :label="car.v_make === 'All'
                                        ? 'All'
                                        : `${Number(car.year) ? car.year : 'YEAR'} ${car.v_make ? car.v_make : 'MAKE'} ${car.v_model ? car.v_model : 'MODEL'}`"
                                    :value="car.id"
                                ></el-option>
                            </el-select>
                        </div>

                        <!-- Info Update Error Indicator -->
                        <button
                            v-if="errors.updateResultInfo"
                            @click="callUpdateResultInfoMethod"
                            class="btn btn-sm d-flex align-items-center h-100 p-0 ml-1"
                            v-tooltip:bottom
                            title="The result info may be out of date. Click to refresh."
                        >
                            <i
                                v-if="isLoading.updateResultInfo"
                                class="fas fa-spinner fa-spin"
                            ></i>
                            <i v-else class="fa-solid fa-rotate-right"></i>
                        </button>
                    </div>

                    <!-- Vehicle Buttons -->
                    <ResultInfoButtons
                        v-if="selectedVehicle"
                        :customer-phone-numbers="customerPhoneNumbers"
                        :selected-customer="selectedCustomer"
                        :selected-vehicle="selectedVehicle"
                        @update-vehicle-notes="getVehicleNotesById({ selectedDealerId: selectedDealerId, vehicleId: selectedVehicle.id })"
                    />
                </div>

                <div v-if="selectedVehicle" class="row mb-2 flex-shrink-0">
                    <div class="col-12">
                        <div class="font-weight-bolder border rounded py-2 px-3 user-details">
                            <div class="row">
                                <div class="col-12 col-sm-4">
                                    <!-- VIN Number -->
                                    <div class="d-flex flex-xs-column flex-sm-row justify-content-between  align-items-center align-items-sm-start align-items-md-center align-items-lg-start  align-items-xl-center border-bottom py-1">
                                        <span class="text-uppercase">VIN</span>
                                        <p>
                                            {{ selectedVehicle.VIN }}
                                            <button
                                                v-if="selectedVehicle.VIN"
                                                @click="$_copyMethods_copyText(selectedVehicle.VIN)"
                                                class="btn badge badge-alpha_neutral--shift"
                                                type="button"
                                                title="Copy vehicle VIN"
                                            ><i class="icon">file_copy</i></button>
                                        </p>
                                    </div>
                                </div>

                                <div class="col-12 col-sm-4">
                                    <!-- Next Appointment -->
                                    <div class="d-flex flex-xs-column flex-sm-row justify-content-between  align-items-center align-items-sm-start align-items-md-center align-items-lg-start  align-items-xl-center border-bottom py-1">
                                      <span class="text-uppercase">Next Appointment</span>
                                      <p>
                                        {{ selectedVehicle.nextAppointment.appointment_date ? $moment(selectedVehicle.nextAppointment.appointment_date, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YY @h:mm A') : 'Not yet scheduled' }}
                                      </p>
                                  </div>
                                </div>

                                <div class="col-12 col-sm-4">
                                    <!-- Last Serviced Date -->
                                    <div class="d-flex flex-xs-column flex-sm-row justify-content-between  align-items-center align-items-sm-start align-items-md-center align-items-lg-start  align-items-xl-center border-bottom py-1">
                                        <span class="text-uppercase">Last Serviced</span>
                                        <p>
                                          {{ selectedVehicle.last_service_date ? $moment(selectedVehicle.last_service_date).format('MM/DD/YY') : 'N/A'}}
                                          {{ selectedVehicle.last_service_date ? `(${$moment(selectedVehicle.last_service_date).fromNow()})` : ''}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Result Info Navigation -->
                <ResultInfoTabs
                    default-tab="pending"
                    :enumerations="{
                        'vehicleWarnings': selectedVehicleWarningsAndRecalls.lastThirtyDaysCount
                    }"
                    :errors="errors"
                    :is-loading-services-info="isLoading"
                    :optional-tabs="['pending']"
                    :selected-tab-id="selectedTabId"
                    :selected-vehicle="selectedVehicle"
                    :messages-pending-approval-length="messagesPendingApprovalBySelectedVehicleID.length"
                    @update-selected-tab-id="selectedTabId = tabId"
                />

                <!-- Tab Box -->
                <simplebar id="simplebar_tab_content" class="scrolling-wrapper">
                    <div class="tab-content" id="myTabContent">
                        <!-- All -->
                        <div class="tab-pane fade py-2 px-0" id="all" role="tabpanel" aria-labelledby="all-tab">
                            <div class="container-fluid">
                                <template v-if="selectedVehicleAllCommunications.length">
                                    <template v-for="(communication, index) in selectedVehicleAllCommunications">
                                        <!-- SMS -->
                                        <SMSMessage
                                            v-if="communication.communicationType === 'sms'"
                                            @contextmenu.native.prevent="$refs.contextMenu.open($event, {
                                                addCase: {
                                                    description: communication.text,
                                                    subject: `SMS: ${truncate(communication.text, 25, '…')}`,
                                                    vehicleId: communication.vehicle_id,
                                                }
                                            })"
                                            :key="index"
                                            :customer-initials="customerInitials"
                                            :selected-customer="selectedCustomer"
                                            :sms='communication'
                                        />
                                        <!-- Activity Log -->
                                        <activity-log
                                            v-if="communication.communicationType === 'activity'"
                                            :key="index"
                                            :activity-name="communication.name"
                                            :asm="{firstName: communication.first_name, lastName: communication.last_name}"
                                            :date-time="communication.date_modified_timezone"
                                            :vehicle="{make: communication.v_make, model: communication.v_model, year: communication.v_year}"
                                        />
                                        <!-- Campaign Event -->
                                        <div v-if="communication.communicationType === 'campaignEvent'" :key="index" class="communication communication--campaign-event">
                                            <div class="communication__icon">
                                                <div v-if="communication.campaign_type === 'Email'" class="icon">email</div>
                                                <div v-else-if="communication.campaign_type === 'Opportunity'" class="icon">campaign</div>
                                                <div v-else class="icon">event</div>
                                            </div>
                                            <div class="communication__content">
                                                <div class="communication__header">
                                                    <strong>{{ communication.campaign_type }}</strong>
                                                    <p>
                                                        {{ communication.name }}<br>
                                                        {{ communication.event_type }}
                                                    </p>
                                                </div>
                                                <div class="communication__item">
                                                    {{ communication.v_year }} {{ communication.v_make }} {{ communication.v_model }}
                                                </div>
                                                <div class="communication__item">
                                                    <small class="white-space-nowrap">
                                                        {{ $moment(communication.date_modified_timezone).format('MM/DD/YY @h:mm A') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Opportunity Status -->
                                        <div v-if="communication.communicationType === 'opportunityStatus'" :key="index" class="communication communication--opportunity-status">
                                            <div class="communication__icon">
                                                <div class="icon">update</div>
                                            </div>
                                            <div class="communication__content">
                                                <div class="communication__header">
                                                    <strong>Opportunity Status</strong>
                                                    <p>{{ communication.status }}</p>
                                                </div>
                                                <div class="communication__item">
                                                    {{ communication.first_name }} {{ communication.last_name }}
                                                </div>
                                                <div v-if="communication.v_make || communication.v_model" class="communication__item">
                                                    {{ communication.v_year }} {{ communication.v_make }} {{ communication.v_model }}
                                                </div>
                                                <div class="communication__item">
                                                    <small class="white-space-nowrap">
                                                        {{ $moment(communication.date_modified_timezone).format('MM/DD/YY @h:mm A') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Phone Calls -->
                                        <div v-if="communication.communicationType === 'phoneCall'" :key="index" class="communication communication--phone-call">
                                            <div class="communication__icon">
                                                <div class="icon">phone</div>
                                            </div>
                                            <div class="communication__content">
                                                <div class="communication__header">
                                                    <strong>Phone Call<span v-if="communication.type">&nbsp;({{ communication.type }})</span></strong>
                                                    <p>{{ communication.status }} {{ communication.duration ? `(${communication.duration}s)` : '' }}</p>
                                                </div>
                                                <div class="communication__item">
                                                    <strong>Received by</strong>
                                                    <p>{{ communication.receiver }}</p>
                                                </div>
                                                <div class="communication__item">
                                                    <small class="white-space-nowrap">
                                                        {{ $moment(communication.DateTime).format('MM/DD/YY @h:mm A') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Phone Call Logs -->
                                        <PhoneCallLog
                                            v-if="communication.communicationType === 'phoneCallLog'"
                                            :key="index"
                                            :phone-call="communication"
                                        />
                                        <!-- Vehicle Notes -->
                                        <div v-if="communication.communicationType === 'vehicleNote'" :key="index" class="communication communication--vehicle-note">
                                            <div class="communication__icon">
                                                <div class="icon">note</div>
                                            </div>
                                            <div class="communication__content">
                                                <div class="communication__header">
                                                    <strong>Vehicle Note</strong>
                                                    <p>{{ communication.description }}</p>
                                                </div>
                                                <div class="communication__item">
                                                    {{ communication.full_name }}
                                                </div>
                                                <div class="communication__item">
                                                    <small class="white-space-nowrap">
                                                        {{ $moment(communication.date_modified).format('MM/DD/YY @h:mm A') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Customer Notes -->
                                        <div v-if="communication.communicationType === 'customerNote'" :key="index" class="communication communication--customer-note">
                                            <div class="communication__icon">
                                                <div class="icon">note</div>
                                            </div>
                                            <div class="communication__content">
                                                <div class="communication__header">
                                                    <strong>Customer Note</strong>
                                                    <p>{{ communication.description }}</p>
                                                </div>
                                                <div class="communication__item">
                                                    {{ communication.full_name }}
                                                </div>
                                                <div class="communication__item">
                                                    <small class="white-space-nowrap">
                                                        {{ $moment(communication.date_modified).format('MM/DD/YY @h:mm A') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Customer Payments -->
                                        <PendingPayment v-if="communication.communicationType === 'payment'" :key="index"
                                            :dealership="userSession.dealership"
                                            :payment="communication"
                                            @add-case="$refs.modalAddACase.showModal({
                                                description: $_servicesInfoMixin_generatePaymentCaseDescription(communication),
                                                subject: communication.name ? communication.name : 'Add a subject',
                                                vehicleId: communication.vehicle_id,
                                            })"
                                            @update-payment="$_servicesInfoMixin_updatePayment({
                                                amount: communication.amount,
                                                paymentId: communication.id,
                                            })"
                                        />

                                        <!-- Case Logs -->
                                        <div v-if="communication.communicationType === 'caseLog'" :key="index" class="communication communication--case-log">
                                            <div class="communication__icon">
                                                <div class="icon">work</div>
                                            </div>
                                            <div class="communication__content">
                                                <div class="communication__header">
                                                    <strong>Case</strong>
                                                    <p>{{ communication.subject }}</p>
                                                    <strong>Description</strong>
                                                    <p>{{ communication.description }}</p>
                                                </div>
                                                <div class="communication__item">
                                                    <strong>Assigned To</strong> {{ communication.assigned_user_first_name }} {{ communication.assigned_user_last_name }}<br>
                                                    <strong>Status</strong> {{ communication.status }}<br>
                                                    <strong>Priority</strong> {{ communication.priority }}<br>
                                                </div>
                                                <div class="communication__item">
                                                    <small class="white-space-nowrap">
                                                        {{ $moment(communication.date_modified).format('MM/DD/YY @h:mm A') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Vehicle Recalls -->
                                        <VehicleRecall
                                            v-if="communication.communicationType === 'vehicleRecall'"
                                            :key="index"
                                            :recall="communication"
                                        />

                                        <!-- Vehicle Warnings -->
                                        <VehicleWarning
                                            v-if="communication.communicationType === 'vehicleWarning'"
                                            :key="index"
                                            :warning="communication"
                                        />
                                    </template>
                                </template>
                                <template v-else>
                                    <div class="row">
                                        <div class="col">
                                            No communications found
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <!-- SMS Chat Log -->
                        <div class="tab-pane fade py-2 px-0" id="messages" role="tabpanel" aria-labelledby="messages-tab">
                            <div class="container-fluid">
                                <template v-if="selectedVehicleSMSMessages.length">
                                    <SMSMessage
                                        v-for="(sms, index) in selectedVehicleSMSMessages"
                                        @contextmenu.native.prevent="$refs.contextMenu.open($event, {
                                            addCase: {
                                                description: sms.text,
                                                subject: `SMS: ${truncate(sms.text, 25, '…')}`,
                                                vehicleId: sms.vehicle_id,
                                            }
                                        })"
                                        :key="index"
                                        :customer-initials="customerInitials"
                                        :selected-customer="selectedCustomer"
                                        :sms='sms'
                                    />
                                </template>
                                <div v-else>No messages to display</div>
                            </div>
                        </div>
                        <!-- Pending -->
                        <div class="tab-pane fade py-2 px-0 show active" id="pending" role="tabpanel" aria-labelledby="pending-tab">
                            <div class="container-fluid">

                                <!-- Pending Customer Payments -->
                                <template v-if="selectedCustomerPayments.length">
                                    <PendingPayment v-for="(selectedCustomerPayment, index) in selectedCustomerPayments" :key="index"
                                        :dealership="userSession.dealership"
                                        :payment="selectedCustomerPayment"
                                        @add-case="$refs.modalAddACase.showModal({
                                            description: $_servicesInfoMixin_generatePaymentCaseDescription(selectedCustomerPayment),
                                            subject: selectedCustomerPayment.name ? selectedCustomerPayment.name : 'Add a subject',
                                            vehicleId: selectedCustomerPayment.vehicle_id,
                                        })"
                                        @update-payment="$_servicesInfoMixin_updatePayment({
                                            amount: selectedCustomerPayment.amount,
                                            paymentId: selectedCustomerPayment.id,
                                        })"
                                    />
                                </template>

                                <!-- Pending Messages -->
                                <template v-if="messagesPendingApprovalBySelectedVehicleID.length">
                                    <div
                                        v-for="pendingMessage in messagesPendingApprovalBySelectedVehicleID"
                                        :key="pendingMessage.ID"
                                        :class="{'border-alpha_primary': messagesPendingApprovalBySelectedVehicleID.length > 1 && pendingMessage.ID === selectedPendingMessageId}"
                                        class="communication communication--pending"
                                    >
                                        <div class="communication__content">
                                            <PendingSMSMessage
                                                :customer="selectedCustomer"
                                                :pending-message="pendingMessage"
                                                :phone-numbers="customerPhoneNumbers"
                                                @update-result-info="callUpdateResultInfoMethod()"
                                            />
                                        </div>
                                    </div>
                                </template>

                                <div v-else>There are no pending messages for the selected vehicle</div>
                            </div>
                        </div>
                        <!-- Phone Calls Log -->
                        <ResultInfoTabPhoneCallLogs
                            v-if="userSession && userSession.hasCallTrackingIntegration"
                            :customer-phone-numbers="customerPhoneNumbers"
                            :date-from="phoneCallLogFiltersDateFrom"
                            :is-loading-get-phone-call-logs="!!isLoading.getPhoneCallLogs"
                            :phone-call-logs="phoneCallLogs"
                            :phone-numbers="phoneCallLogFiltersPhoneNumbers"
                            @updateDateFrom="(dateFrom) => phoneCallLogFiltersDateFrom = dateFrom"
                            @updatePhoneNumbers="(phoneNumbers) => phoneCallLogFiltersPhoneNumbers = phoneNumbers"
                            @getPhoneCallLogs="getPhoneCallLogs"
                        />
                        <!-- Opportunities -->
                        <div class="tab-pane fade py-2 px-0" id="opportunities" role="tabpanel" aria-labelledby="opportunities-tab">
                            <div class="container-fluid">
                                <div v-if="selectedVehicle" class="row">
                                    <div class="col">
                                        <button
                                            class="btn btn-sm btn-alpha_primary mb-2"
                                            type="button"
                                            data-toggle="modal"
                                            @click="$refs.modalOpportunity.showModal()"
                                        >Create Opportunity</button>
                                    </div>
                                </div>
                                <div v-for="(opportunity, index) in selectedVehicleOpportunitiesSorted" :key="index" class="communication communication--opportunity">
                                    <div class="communication__icon">
                                        <div class="icon">campaign</div>
                                    </div>
                                    <div class="communication__content">
                                        <div class="communication__header">
                                            <strong>Opportunity</strong>
                                            <p>{{ opportunity.opportunitiesName }}</p>
                                        </div>
                                        <div class="communication__item">
                                            {{ opportunity.assigned_user_id ? getASMNameById(opportunity.assigned_user_id) : 'None' }}
                                        </div>
                                        <div class="communication__item">
                                            <abbr v-for="(activity, index) in opportunity.activity" :key="index" :title="activity">
                                                {{ activity.charAt(0) }}
                                            </abbr>
                                        </div>
                                        <div class="communication__item d-flex">
                                            <SelectOpportunityStatus
                                                class="mr-1"
                                                :assigned-user-id="opportunity.assigned_user_id"
                                                :opportunity-id="opportunity.opp_id"
                                                :value="opportunity.sales_stage"
                                                @update-result-info="callUpdateResultInfoMethod()"
                                            />
                                            <div class="btn-group d-inline">
                                                <button class="btn btn-sm btn-alpha_bg--shift btn-flat dropdown-toggle border" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    Log Action
                                                </button>
                                                <div class="dropdown-menu">
                                                    <button @click="$_servicesInfoMixin_addActivity('Opportunities', opportunity.opp_id, 'Call').then(() => callUpdateResultInfoMethod())" type="button" class="dropdown-item">Log Call</button>
                                                    <button @click="$_servicesInfoMixin_addActivity('Opportunities', opportunity.opp_id, 'Email').then(() => callUpdateResultInfoMethod())" type="button" class="dropdown-item">Log Email</button>
                                                    <button @click="$_servicesInfoMixin_addActivity('Opportunities', opportunity.opp_id, 'Text').then(() => callUpdateResultInfoMethod())" type="button" class="dropdown-item">Log Text</button>
                                                    <button @click="$_servicesInfoMixin_addActivity('Opportunities', opportunity.opp_id, 'Other').then(() => callUpdateResultInfoMethod())" type="button" class="dropdown-item">Log Other</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="communication__item">
                                            <small class="white-space-nowrap">
                                                {{ $moment(opportunity.date_entered).format('MM/DD/YY @h:mm A') }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Notes -->
                        <div class="tab-pane fade py-2 px-0" id="notes" role="tabpanel" aria-labelledby="contact-tab">
                            <div class="container-fluid">
                                <el-alert
                                    v-if="errors.getVehicleNotesById"
                                    :title="errors.getVehicleNotesById"
                                    :closable="false"
                                    class="mb-2"
                                    type="error"
                                    show-icon
                                ></el-alert>

                                <template v-if="(! isLoading.getCustomerNotesById && ! isLoading.getVehicleNotesById) && selectedVehicleAndCustomerNotes.length">
                                    <div v-for="(note, index) in selectedVehicleAndCustomerNotes" :key="index" class="communication communication--activity">
                                        <div class="communication__icon">
                                            <div class="icon">note</div>
                                        </div>
                                        <div class="communication__content">
                                            <div class="communication__header">
                                                <strong v-if="note.communicationType === 'vehicleNote'">Vehicle Note</strong>
                                                <strong v-else-if="note.communicationType === 'customerNote'">Customer Note</strong>
                                                <p>{{ note.description }}</p>
                                            </div>
                                            <div class="communication__item">
                                                {{ note.full_name }}
                                            </div>
                                            <div class="communication__item">
                                                <small class="white-space-nowrap">
                                                    {{ $moment(note.date_modified).format('MM/DD/YY @h:mm A') }}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else-if="isLoading.getCustomerNotesById || isLoading.getVehicleNotesById">
                                    <div class="row">
                                        <div class="col">
                                            Loading...
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="row">
                                        <div class="col">
                                            No notes found
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <!-- Automated Communication -->
                        <div class="tab-pane fade py-2 px-0" id="communication" role="tabpanel" aria-labelledby="communication-tab">
                            <div class="container-fluid">
                                <template v-if="selectedVehicleAllCommunications.length">
                                    <template v-for="(communication, index) in selectedVehicleAllCommunications">
                                        <!-- SMS -->
                                        <div v-if="communication.communicationType === 'sms' && communication.generated_from === 'System'" :key="index" class="communication communication--sms">
                                            <div class="communication__icon">
                                                <div class="icon">chat</div>
                                            </div>
                                            <div class="communication__content">
                                                <div class="communication__header">
                                                    <strong>SMS</strong>
                                                    <p>{{ communication.text }}</p>
                                                </div>
                                                <div v-if="communication.first_name_ASM || communication.last_name_ASM" class="communication__item">
                                                    {{ communication.first_name_ASM }} {{ communication.last_name_ASM }}
                                                </div>
                                                <div class="communication__item">
                                                    <small class="white-space-nowrap">
                                                        {{ $moment(communication.DateTime).format('MM/DD/YY @h:mm A') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Campaign Event -->
                                        <div v-if="communication.communicationType === 'campaignEvent'" :key="index" class="communication communication--campaign-event">
                                            <div class="communication__icon">
                                                <div v-if="communication.campaign_type === 'Email'" class="icon">email</div>
                                                <div v-else-if="communication.campaign_type === 'Opportunity'" class="icon">campaign</div>
                                                <div v-else class="icon">event</div>
                                            </div>
                                            <div class="communication__content">
                                                <div class="communication__header">
                                                    <strong>{{ communication.campaign_type }}</strong>
                                                    <p>
                                                        {{ communication.name }}<br>
                                                        {{ communication.event_type }}
                                                    </p>
                                                </div>
                                                <div class="communication__item">
                                                    {{ communication.v_year }} {{ communication.v_make }} {{ communication.v_model }}
                                                </div>
                                                <div class="communication__item">
                                                    <small class="white-space-nowrap">
                                                        {{ $moment(communication.date_modified_timezone).format('MM/DD/YY @h:mm A') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Opportunity Status -->
                                        <div v-if="communication.communicationType === 'opportunityStatus'" :key="index" class="communication communication--opportunity-status">
                                            <div class="communication__icon">
                                                <div class="icon">update</div>
                                            </div>
                                            <div class="communication__content">
                                                <div class="communication__header">
                                                    <strong>Opportunity Status</strong>
                                                    <p>{{ communication.status }}</p>
                                                </div>
                                                <div class="communication__item">
                                                    {{ communication.first_name }} {{ communication.last_name }}
                                                </div>
                                                <div v-if="communication.v_make || communication.v_model" class="communication__item">
                                                    {{ communication.v_year }} {{ communication.v_make }} {{ communication.v_model }}
                                                </div>
                                                <div class="communication__item">
                                                    <small class="white-space-nowrap">
                                                        {{ $moment(communication.date_modified_timezone).format('MM/DD/YY @h:mm A') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </template>
                                <template v-else>
                                    <div class="row">
                                        <div class="col">
                                            No automated communications found
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <!-- Vehicle Health -->
                        <div class="tab-pane fade py-2 px-0" id="vehicle-health" role="tabpanel" aria-labelledby="vehicle-health-tab">
                            <div class="container-fluid">
                                <template v-if="selectedVehicleWarningsAndRecalls.results.length">
                                    <div class="row" v-for="(data, index) in selectedVehicleWarningsAndRecalls.results" :key="index">
                                        <!-- Vehicle Warnings -->
                                        <div v-if="data.infoType === 'warning'" class="col">
                                            <VehicleWarning
                                                :warning="data"
                                            />
                                        </div>
                                        <!-- Vehicle Recalls -->
                                        <div v-if="data.infoType === 'recall'" class="col">
                                            <VehicleRecall
                                                :recall="data"
                                                :key="index"
                                            />
                                        </div>
                                    </div>
                                </template>
                                <template v-else-if="isLoading.getWarningsByVin">
                                    <div class="row">
                                        <div class="col">
                                            Loading...
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="row">
                                        <div class="col">
                                            No vehicle recalls or warnings found
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </simplebar>

                <!-- SMS Composition -->
                <SMSComposition
                    :asm="selectedVehicleASM"
                    :customer="selectedCustomer"
                    :file="file"
                    :file-urls="fileUrls"
                    :phone-number="selectedCustomerSendToInput"
                    :phone-numbers="customerPhoneNumbers"
                    :sms-message="smsMessage"
                    :rewards-link-tiny-url.sync="rewardsLinkTinyUrl"
                    :vehicle="selectedVehicle"
                    :vehicle-id="selectedVehicleId"
                    :vehicles="filteredCars"
                    component-name="PendingMessage"
                    @updateSendToInput="updateSendToInput"
                    @updateSelectedVehicleId="updateSelectedVehicleId"
                    @updateSMSMessage="updateSMSMessage"
                    @updateSMSMessages="updateSMSMessages"
                    @updateFile="updateFile"
                    @updateFileUrls="updateFileUrls"
                    @success="updateSMSMessages"
                />
            </div>

        </div>

        <div v-if="selectedCustomer" class="customer-info-wrapper order-1 order-md-2 bg-alpha_bg">
            <customer-info
                :are-phone-labels-available="true"
                :asms="asms"
                :cars="resultInfo.Cars"
                :customer="selectedCustomer"
                :custom-phone-numbers="resultInfo.CustomPhoneNumbers"
                :filtered-cars="filteredCars"
                :isLoadingSentiment="isLoading.getCustomersLastMessageSentimentById"
                :last-message-sentiment="customersLastMessageSentiment"
                :phone-numbers="customerPhoneNumbers"
                :rewards-link-tiny-url.sync="rewardsLinkTinyUrl"
                :selected-vehicle="selectedVehicle"
                :selected-vehicle-id="selectedVehicleId"
                :selected-a-s-m="selectedVehicleASM"
                @update-customer-notes="getCustomerNotesById({ selectedDealerId: selectedDealerId, customerId: selectedCustomer.ID })"
                @update-result-info="callUpdateResultInfoMethod()"
            />
        </div>

        <!-- Add Case Modal -->
        <modal-add-a-case
            ref="modalAddACase"
            :asms='asms'
            :customer="selectedCustomer"
            :filtered-cars="filteredCars"
            :selected-a-s-m="selectedVehicleASM"
            :selected-vehicle-id="selectedVehicleId"
        />

        <!-- Create Opportunity Modal -->
        <ModalOpportunity
            v-if="selectedVehicle"
            ref="modalOpportunity"
            :customer="selectedCustomer"
            :vehicle="selectedVehicle"
            @success="callUpdateResultInfoMethod()"
        />

        <!-- Context Menu -->
        <vue-context ref="contextMenu" v-slot="{ data }">
            <li v-if="data && data.addCase">
                <a @click.prevent="$refs.modalAddACase.showModal({ subject: data.addCase.subject, description: data.addCase.description, vehicleId: data.addCase.vehicleId })">Add Case</a>
            </li>
        </vue-context>
    </div>
</template>

<script>
import ModalOpportunity from "@/components/modals/ModalOpportunity.vue";
import PendingPayment from '@/components/services/child-components/PendingPayment.vue';
import PendingSMSMessage from '@/components/services/child-components/PendingSMSMessage.vue';
import PhoneCallLog from '@/components/services/child-components/PhoneCallLog.vue';
import ResultInfoButtons from '@/components/services/child-components/ResultInfoButtons.vue';
import ResultInfoTabs from '@/components/services/child-components/ResultInfoTabs.vue';
import ResultInfoTabPhoneCallLogs from '@/components/services/child-components/ResultInfoTabPhoneCallLogs.vue';
import SelectOpportunityStatus from '@/components/services/child-components/SelectOpportunityStatus.vue';
import SMSComposition from '@/components/services/child-components/SMSComposition.vue';
import SMSMessage from '@/components/services/child-components/SMSMessage.vue';
import VehicleRecall from '@/components/services/child-components/VehicleRecall.vue';
import VehicleWarning from '@/components/services/child-components/VehicleWarning.vue';
import { formatPhoneNumber } from '@/filters/phoneNumbers';
import { convertUrlsToHtml } from '@/filters/stringFormats';
import { generalMixin } from '@/mixins/generalMixin';
import { copyMethods } from '@/mixins/helperMixins';
import { servicesInfoMixin } from '@/mixins/servicesInfoMixin';
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
    components: {
        ModalOpportunity,
        PendingPayment,
        PendingSMSMessage,
        PhoneCallLog,
        ResultInfoButtons,
        ResultInfoTabs,
        ResultInfoTabPhoneCallLogs,
        SelectOpportunityStatus,
        SMSComposition,
        SMSMessage,
        VehicleRecall,
        VehicleWarning,
    },

    mixins: [copyMethods, generalMixin, servicesInfoMixin],

    data() {
        return {
            selectedTabId: 'pending',
        }
    },

    computed: {
        ...mapState('services/pendingMessageInfo', {
            customerNotes: 'customerNotes',
            customersLastMessageSentiment: 'customersLastMessageSentiment',
            errors: 'errors',
            file: 'file',
            fileUrls: 'fileUrls',
            isLoading: 'isLoading',
            phoneCallLogs: 'phoneCallLogs',
            resultInfo: 'resultInfo',
            selectedCustomerSendToInput: 'selectedCustomerSendToInput',
            selectedPendingMessageId: 'selectedPendingMessageId',
            smsMessage: 'smsMessage',
            vehicleNotes: 'vehicleNotes',
            vehicleWarnings: 'vehicleWarnings',
        }),

        ...mapGetters('services/pendingMessageInfo', {
            customerInitials: 'customerInitials',
            customerPhoneNumbers: 'customerPhoneNumbers',
            filteredCars: 'filteredCars',
            selectedCustomer: 'selectedCustomer',
            selectedVehicle: 'selectedVehicle',
            selectedVehicleASM: 'selectedVehicleASM',
            selectedVehicleOpportunitiesSorted: 'selectedVehicleOpportunitiesSorted',
        }),

        messagesPendingApproval() {
            let messages = [];

            if (this.resultInfo.SMSHistory && this.resultInfo.SMSHistory.length) {
                messages = this.resultInfo.SMSHistory.filter(message => message.status === 'Approval Pending');
            }

            return messages;
        },

        messagesPendingApprovalBySelectedVehicleID() {
            let messages = [];

            if (this.messagesPendingApproval && this.messagesPendingApproval.length && this.selectedVehicleId) {
                messages = this.messagesPendingApproval.filter(message => message.vehicle_id === this.selectedVehicleId);
            } else {
                messages = this.messagesPendingApproval.filter(message => {
                    return !this.selectedVehicleId;
                });
            }

            return messages;
        },

        selectedVehicleActivityLog() {
            if (!this.resultInfo.ActivityLog) {
                return [];
            }
            return this.resultInfo.ActivityLog.filter(activity => {
                return !this.selectedVehicleId || (activity.vehicle_id == this.selectedVehicleId);
            });
        },

        selectedVehicleSMSMessages() {
            if (!this.resultInfo.SMSHistory) {
                return [];
            }
            return this.resultInfo.SMSHistory.filter(sms => {
                return !this.selectedVehicleId || !sms.vehicle_id || (sms.vehicle_id == this.selectedVehicleId && sms.status.toLowerCase() !== "approval pending");
            });
        },

        selectectedVehiclePhoneCalls() {
            if (!this.resultInfo.PhoneCalls) {
                return [];
            }
            return this.resultInfo.PhoneCalls.filter(phoneCall => {
                return !this.selectedVehicleId || (phoneCall.vehicle_id == this.selectedVehicleId);
            });
        },

        selectectedVehicleCampaignEventsLog() {
            if (!this.resultInfo.CampaignEventsLog) {
                return [];
            }
            return this.resultInfo.CampaignEventsLog.filter(event => {
                return !this.selectedVehicleId || (event.vehicle_id == this.selectedVehicleId);
            });
        },

        selectectedVehicleOpportunitiesStatusLog() {
            if (!this.resultInfo.OpportunitiesStatusLog) {
                return [];
            }
            return this.resultInfo.OpportunitiesStatusLog.filter(opportunityStatus => {
                return !this.selectedVehicleId || (opportunityStatus.vehicle_id == this.selectedVehicleId);
            });
        },

        selectedVehicleAndCustomerNotes() {
            const notes = [];

            // Push customer notes to array
            this.customerNotes.forEach(customerNote => {
                customerNote.communicationType = 'customerNote';
                notes.push(customerNote);
            });

            // Push vehicle notes to array
            this.vehicleNotes.forEach(vehicleNote => {
                vehicleNote.communicationType = 'vehicleNote';
                notes.push(vehicleNote);
            });

            // Sort notes
            // Sort automated communication array by date
            notes.sort((a, b) => {
                const aDate = a.date_modified ? a.date_modified : '';
                const bDate = b.date_modified ? b.date_modified : '';

                return aDate && bDate ? new Date(aDate) - new Date(bDate) : aDate - bDate;
            });

            return notes;
        },

        selectedCustomerPayments() {
            if (!this.resultInfo.Payments) {
                return [];
            }
            return this.resultInfo.Payments.filter(payment => {
                return !this.selectedVehicleId || (payment.vehicle_id === this.selectedVehicleId);
            });
        },

        selectedVehicleCaseLog() {
            if (!this.resultInfo.CasesLog) return [];

            return this.resultInfo.CasesLog.filter(caseLog => {
                return !this.selectedVehicleId || (caseLog.vehicle_id === this.selectedVehicleId);
            });
        },

        selectedVehicleWarningsAndRecalls() {
            const allWarningsAndRecalls = {
                results: [],
                lastThirtyDaysCount: 0,
            };

            // Push warnings to array
            this.vehicleWarnings?.forEach(warning => {
                warning.infoType = 'warning';
                warning.normalizedDate = warning.time_triggered;
                allWarningsAndRecalls.results.push(warning);
            });

            // Push vehicle recalls to array
            this.selectedVehicle?.recall?.forEach(recall => {
                recall.infoType = 'recall';
                recall.normalizedDate = recall.recall_date;
                allWarningsAndRecalls.results.push(recall);
            });

            // Sort warnings and recalls
            allWarningsAndRecalls.results.sort((a, b) => {
                const aDate = a.normalizedDate;
                const bDate = b.normalizedDate;

                return aDate && bDate
                    ? new Date(aDate) - new Date(bDate)
                    : aDate - bDate;
            });

            // Count warnings and recalls from last 30 days
            allWarningsAndRecalls.lastThirtyDaysCount = allWarningsAndRecalls
                .results
                .filter(warningOrRecall => {
                    const warningOrRecallDate = warningOrRecall.normalizedDate;
                    const thirtyDaysAgo = new Date();
                    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

                    return warningOrRecallDate
                        && new Date(warningOrRecallDate) > thirtyDaysAgo;
                })
                .length;

            return allWarningsAndRecalls;
        },

        selectedVehicleAllCommunications() {
            const allCommunications = [];

            // Push activities to array
            this.selectedVehicleActivityLog.forEach(activity => {
                activity.communicationType = 'activity';
                allCommunications.push(activity);
            });

            // Push SMS messages to array
            this.selectedVehicleSMSMessages.forEach(message => {
                message.communicationType = 'sms';
                allCommunications.push(message);
            });

            // Push opportunity status to array
            this.selectectedVehicleOpportunitiesStatusLog.forEach(opportunityStatus => {
                opportunityStatus.communicationType = 'opportunityStatus';
                allCommunications.push(opportunityStatus);
            });

            // Push campaign events to array
            this.selectectedVehicleCampaignEventsLog.forEach(event => {
                event.communicationType = 'campaignEvent';
                allCommunications.push(event);
            });

            // Push customer notes to array
            this.customerNotes.forEach(customerNote => {
                customerNote.communicationType = 'customerNote';
                allCommunications.push(customerNote);
            });

            // Push notes to array
            this.vehicleNotes.forEach(vehicleNote => {
                vehicleNote.communicationType = 'vehicleNote';
                allCommunications.push(vehicleNote);
            });

            // Push phone calls to array
            this.selectectedVehiclePhoneCalls.forEach(phoneCall => {
                phoneCall.communicationType = 'phoneCall';
                allCommunications.push(phoneCall);
            });

            // Push payments to array
            this.selectedCustomerPayments.forEach(payment => {
                payment.communicationType = 'payment';
                allCommunications.push(payment);
            });

            // Push case logs to array
            this.selectedVehicleCaseLog.forEach(caseLog => {
                caseLog.communicationType = 'caseLog';
                allCommunications.push(caseLog);
            });

            // Push phone call logs to array
            this.phoneCallLogs.forEach(phoneCallLog => {
                phoneCallLog.communicationType = 'phoneCallLog';
                allCommunications.push(phoneCallLog);
            });

            // Push other recalls to array
            this.selectedVehicle?.recall?.forEach(recall => {
                recall.communicationType = 'vehicleRecall';
                allCommunications.push(recall);
            });

            // Push vehicle warnings to array
            this.vehicleWarnings.forEach(warning => {
                warning.communicationType = 'vehicleWarning';
                allCommunications.push(warning);
            });

            // Sort automated communication array by date
            allCommunications.sort((a, b) => {
                const aDate = a.DateTime ??
                    a.date_modified_timezone ??
                    a.date_modified ??
                    a.start_date_time ??
                    a.displayDate ??
                    a.recall_date ??
                    a.time_triggered ??
                    '';
                const bDate = b.DateTime ??
                    b.date_modified_timezone ??
                    b.date_modified ??
                    b.start_date_time ??
                    b.displayDate ??
                    b.recall_date ??
                    b.time_triggered ??
                    '';

                return aDate && bDate ? new Date(aDate) - new Date(bDate) : aDate - bDate;
            });

            return allCommunications;
        },

        ///////////////////////////////////////////////////////////////////////
        // Getter/Setters
        ///////////////////////////////////////////////////////////////////////

        phoneCallLogFiltersDateFrom: {
            get() {
                return this.$store.state.services.pendingMessageInfo.phoneCallLogFilters.dateFrom;
            },
            set(value) {
                this.$store.commit('services/pendingMessageInfo/SET_PHONE_CALL_LOG_FILTERS', { attribute: 'dateFrom', value: value });
            }
        },

        phoneCallLogFiltersPhoneNumbers: {
            get() {
                return this.$store.state.services.pendingMessageInfo.phoneCallLogFilters.phoneNumbers;
            },
            set(value) {
                this.$store.commit('services/pendingMessageInfo/SET_PHONE_CALL_LOG_FILTERS', { attribute: 'phoneNumbers', value: value });
            }
        },

        rewardsLinkTinyUrl: {
            get() {
                return this.$store.state.services.pendingMessageInfo.rewardsLinkTinyUrl;
            },
            set(value) {
                this.$store.commit('services/pendingMessageInfo/SET_REWARDS_LINK_TINY_URL', value);
            }
        },

        selectedVehicleId: {
            get() {
                return this.$store.state.services.pendingMessageInfo.selectedVehicleId;
            },
            set(id) {
                const selectedVehicle = this.resultInfo.Cars.find(car => {
                    return car.id == id;
                });

                this.$store.commit('services/pendingMessageInfo/SET_SELECTED_VEHICLE_ID', id);
            }
        },

        smsMessage: {
            get() {
                return this.$store.state.services.pendingMessageInfo.smsMessage;
            },
            set(message) {
                this.$store.commit('services/pendingMessageInfo/SET_SMS_MESSAGE', message);
            }
        },
    },

    watch: {
        // Perform actions when vehicle changes
        selectedVehicleId() {
            // Get vehicle notes
            this.getVehicleNotesById({ selectedDealerId: this.selectedDealerId, vehicleId: this.selectedVehicle?.id });

            // Scroll SimpleBar tab content to bottom/top on change
            if (this.selectedTabId === 'recalls') {
                this.$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', position: 'top', timeout: 0});
            } else {
                this.$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', timeout: 250});
            }
        },

        // Perform actions when selected vehicle SMS messages change
        selectedVehicleSMSMessages() {
            if (this.selectedTabId !== 'pending') {
                this.$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', timeout: 0});
            }

            if (! this.isLoadingGlobal.markSMSNotificationsRead) {
                this.$_servicesInfoMixin_findAndMarkSMSNotificationsRead(this.selectedVehicleSMSMessages, this.selectedCustomer.ID);
            }
        },

        // Perform actions when vehicle notes change
        vehicleNotes() {
            this.$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', timeout: 0});
        },

        // Perform actions when SMS notifications change
        smsNotifications: {
            handler(newValue){
                this.$_servicesInfoMixin_findAndMarkSMSNotificationsRead(this.selectedVehicleSMSMessages, this.selectedCustomer.ID);
            }, deep: true,
        },
    },

    created() {
        // Find and mark sms notifications as read
        this.$_servicesInfoMixin_findAndMarkSMSNotificationsRead(this.selectedVehicleSMSMessages, this.selectedCustomer.ID);
    },

    mounted() {
        // Scroll message box to bottom
        this.$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', timeout: 250});

        // Get vehicle notes
        if(this.selectedVehicle && this.selectedVehicle.id) {
            this.getVehicleNotesById({ selectedDealerId: this.selectedDealerId, vehicleId: this.selectedVehicle.id });
        }

        // Get vehicle warnings by VIN
        if (this.selectedVehicle?.VIN) {
            this.getWarningsByVin({
                selectedDealerId: this.selectedDealerId,
                vin: this.selectedVehicle.VIN
            });
        }

        // Get customer notes
        this.getCustomerNotesById({ selectedDealerId: this.selectedDealerId, customerId: this.selectedCustomer.ID });
    },

    methods : {
        /**
         * Vuex pendingMessageInfo actions
         */
        ...mapActions('services/pendingMessageInfo', {
            getCustomerNotesById: 'getCustomerNotesById',
            getPhoneCallLogs: 'getPhoneCallLogs',
            getVehicleNotesById: 'getVehicleNotesById',
            getWarningsByVin: 'getWarningsByVin',
            updateResultInfo: 'updateResultInfo',
        }),

        /**
         * Imported methods
         */
        convertUrlsToHtml,
        formatPhoneNumber,

        /**
         * Call updateResultInfo method with required parameters
         */
        callUpdateResultInfoMethod() {
            this.updateResultInfo({
                selectedDealerId: this.selectedDealerId,
                customerId: this.selectedCustomer.ID
            });
        },

        /**
         * Update send to input
         */
        updateSendToInput(sendToInput) {
            this.$store.commit('services/pendingMessageInfo/SET_SELECTED_CUSTOMER_SEND_TO_INPUT', sendToInput);
        },

        /**
         * Update selected vehicle Id in store
         */
        updateSelectedVehicleId(id) {
            const selectedVehicle = this.resultInfo.Cars.find(car => {
                return car.id == id;
            });

            this.$store.commit('services/pendingMessageInfo/SET_SELECTED_VEHICLE_ID', id);
        },

        /**
         * Update SMS message in store
         */
        updateSMSMessage(message) {
            this.$store.commit('services/pendingMessageInfo/SET_SMS_MESSAGE', message);
        },

        /**
         * Update file in store
         */
        updateFile(file) {
            this.$store.commit('services/pendingMessageInfo/SET_FILE', file);
        },

        /**
         * Update file URLs in store
         */
        updateFileUrls(fileUrls) {
            this.$store.commit('services/pendingMessageInfo/SET_FILE_URLS', fileUrls);
        },

        /**
         * Update SMS message log
         */
        updateSMSMessages() {
            this.callUpdateResultInfoMethod();
            this.$store.commit('services/pendingMessageInfo/SET_SMS_MESSAGE', '');
            this.$store.commit('services/pendingMessageInfo/SET_FILE', '');
            this.$store.commit('services/pendingMessageInfo/SET_FILE_URLS', []);
        },

        /**
         * Get ASM name by ID
         */
        getASMNameById(asmId) {
            const foundASM = this.asms.find(asm => asm.asmId === asmId);
            if (! foundASM) {
                return 'None';
            }

            let asmName = foundASM.first_name ?? '';
            asmName += foundASM.last_name ? ` ${foundASM.last_name}` : '';

            if (! asmName) {
                asmName = foundASM.asmName ?? '(ASM Name Not Provided)';
            }

            return asmName;
        },
    },
}

</script>

<style lang="scss" scoped>
@import '~/partials/services-info.scss';
</style>
