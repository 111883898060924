<style lang="scss" scoped>
.metabase-dashboard {
    iframe {
        width: 100%;
        height: calc(100vh - 160px);
    }

    i.fa-spin {
        font-size: 32px;
    }
}

@media print {
    .metabase-dashboard {
        padding: 4px !important;

        iframe {
            width: 2551px;
            height: 3295px;
        }
    }

    @page {
        size: 2551px 3295px; // letter size 300ppi
        margin: 0;
    }
}
</style>
<template>
    <div class="container-fluid p-sm-2 metabase-dashboard">
        <div class="d-flex d-print-none justify-content-between row mb-2">
            <div class="col-auto d-flex flex-row">
                <!-- Dealership Select -->
                <div class="input-group input-group-sm mr-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Dealerships</span>
                    </div>
                    <el-select
                        class="select-blue hide-overflow-tags w-100"
                        v-model="selectedDealerships"
                        size="mini"
                        multiple
                        collapse-tags
                        placeholder="Select"
                        :disabled="!dealerships.length"
                    >
                        <el-option
                            v-for="(dealership, index) in dealerships"
                            :key="index"
                            :label="`${dealership.name} (${dealership.dealer_number})`"
                            :value="dealership.dealer_id"
                        >
                        </el-option>
                    </el-select>
                </div>
                <slot></slot>
            </div>
        </div>

        <div
            v-if="isLoading.getDashboardUrl"
            class="d-flex justify-content-center mt-2"
        >
            <i class="fas fa-circle-notch fa-spin"></i>
        </div>

        <iframe
            v-else
            frameborder="0"
            allowtransparency
            :src="dashboardURL"
        ></iframe>
    </div>
</template>

<script>
import { formReportMixin } from "@/components/reports/mixins/formReportMixin";

export default {
    props: {
        idDashboard: {
            type: Number,
        },
        additionalFilters: {
            type: Object,
            default: {},
        },
    },

    mixins: [formReportMixin],

    data() {
        return {
            dashboardURL: undefined,
            isLoading: {
                getDashboardUrl: false,
            },
            dealerships: [],
            selectedDealerships: [],
        };
    },

    created() {
        this.$_formReportMixin_getListOfDealerships().then((dealerships) => {
            this.dealerships = dealerships;
            this.getDashboardUrl();
        });
    },

    methods: {
        getDashboardUrl() {
            this.isLoading.getDashboardUrl = true;
            axios
                .get(
                    route("api.metabase.getDashboardUrl", {
                        idDashboard: this.idDashboard,
                        dealershipIds: this.selectedDealerships.length
                            ? this.selectedDealerships
                            : this.dealerships.map(
                                  (dealership) => dealership.dealer_id
                              ),
                        additionalFilters: this.additionalFilters,
                    })
                )
                .then((response) => {
                    this.dashboardURL = response.data;
                })
                .catch((error) => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }

                    this.$message({
                        type: "error",
                        message: "Unable to load dashboard URL.",
                    });
                })
                .finally(() => {
                    this.isLoading.getDashboardUrl = false;
                });
        },
    },

    watch: {
        selectedDealerships() {
            this.getDashboardUrl();
        },
        additionalFilters() {
            this.getDashboardUrl();
        },
    },
};
</script>
