<template>
    <div
        id="notifications-sidebar"
        class="notifications-sidebar"
        :class="{'expanded': showNotificationsTray}"
    >
        <div>
            <div class="d-flex justify-content-between align-items-center mb-4">
                <div class="font-20 font-weight-bolder">
                    Notifications
                    <i v-if="isGettingNotifications || isMarkingNotificationsRead" class="fas fa-circle-notch fa-spin"></i>
                    <span v-else class="font-12">
                        ({{ allNotificationsSortAndFiltered.notifications.length }})
                    </span>
                </div>
                <a
                    @click="showNotificationsTray = !showNotificationsTray"
                    href="#"
                    class="close-sidebar badge badge-delta_bg"
                ><i class="icon">chevron_right</i></a>
            </div>

            <div v-if="allNotificationsSortAndFiltered.notifications.length" class="d-flex justify-content-between align-items-center mb-3">
                <button
                    v-if="hasUnreadNotifications"
                    @click="clearDisplayedNotifications"
                    type="button"
                    class="btn btn-sm btn-delta_primary btn-block mr-2"
                    :disabled="isGettingNotifications || isMarkingNotificationsRead"
                >Mark All Read</button>
                <button
                    v-else
                    @click="dismissDisplayedNotifications"
                    type="button"
                    class="btn btn-sm btn-delta_primary btn-block mr-2"
                    :disabled="isGettingNotifications || isDismissingNotifications"
                >Dismiss All</button>
                <button
                    @click="areNotificationsMuted = !areNotificationsMuted"
                    type="button"
                    class="btn btn-sm btn-delta_primary mr-2"
                >
                    <i
                        class="fa-solid"
                        :class="areNotificationsMuted
                            ? 'fa-volume-slash'
                            : 'fa-volume'"
                    ></i></button>
                <el-switch
                    v-model="showAllDealershipNotifications"
                    active-text=""
                    inactive-text=""
                    title="Show/hide other dealership notifications"
                ></el-switch>
            </div>

            <template v-if="allNotificationsSortAndFiltered.total">
                <transition-group name="notification-transition" appear>
                    <template v-for="(notification) in allNotificationsSortAndFiltered.notifications">
                        <!-- SMS Notifications -->
                        <div
                            v-if="notification.notificationType === 'sms'"
                            :key="`sms_${notification.id}`"
                            class="notification"
                            :class="{'unread': !notification.read_at}"
                        >
                            <div class="notification__title">
                                <div class="notification-sidebar__title font-weight-bold" v-if="notification.generated_from === 'payment'">Payment Update</div>
                                <div class="notification-sidebar__title font-weight-bold" v-else-if="['lead', 'leadwidget'].includes(notification.generated_from)">Lead Message</div>
                                <div class="notification-sidebar__title font-weight-bold" v-else-if="notification.type === 'approval pending'">Pending Message</div>
                                <div class="notification-sidebar__title font-weight-bold" v-else>Text Message</div>
                            </div>
                            <div class="notification__content">
                                <strong>{{ notification.customer_name }}</strong><br>
                                <span v-safe-html="notification.text"></span>
                            </div>
                            <div class="notification__dealership" v-if="userSession && userSession.dealership && userSession.dealership.dealer_number !== notification.dealership.dealer_number">
                                {{ notification.dealership.name}} ({{ notification.dealership.dealer_number }})
                            </div>
                            <div class="notification__buttons">
                                <button
                                    @click="markSMSNotificationsRead({
                                        ids: [notification.id],
                                        selectedDealerId: selectedDealerId,
                                        undo: Boolean(notification.read_at),
                                    })"
                                    class="notification__button"
                                    :disabled="isLoadingGlobal.getSMSNotifications"
                                    type="button"
                                >Mark {{ notification.read_at ? 'Unread' : 'Read'}}</button>
                                <template v-if="userSession && userSession.dealership && userSession.dealership.dealer_number === notification.dealership.dealer_number">
                                    <router-link v-if="notification.type === 'approval pending'"
                                        class="notification__button"
                                        :to="{
                                            name: 'services-pending-messages',
                                            query: {
                                                customer_id: notification.customer_id,
                                                vehicle_id: notification.vehicle_id ? notification.vehicle_id : null,
                                            }
                                        }"
                                    >View</router-link>
                                    <router-link v-else-if="['lead', 'leadwidget'].includes(notification.generated_from)"
                                        class="notification__button"
                                        :to="{
                                            name: 'services-leads',
                                            query: {
                                                lead_id: notification.customer_id,
                                                notification: true,
                                            }
                                        }"
                                    >View</router-link>
                                    <router-link v-else
                                        class="notification__button"
                                        :to="{
                                            name: 'services-recent',
                                            query: {
                                                customer_id: notification.customer_id,
                                                auto_campaign_id: notification.auto_campaign_id,
                                                sms_date_time: notification.date,
                                            }
                                        }"
                                    >View</router-link>
                                </template>
                                <template v-else>
                                    <a v-if="notification.type === 'approval pending'"
                                        class="notification__button"
                                        :href="route('dealerships.show', {
                                            route_name: 'services/pending-messages',
                                            dealership: notification.dealership.id,
                                            customer_id: notification.customer_id?notification.customer_id:null,
                                            vehicle_id: notification.vehicle_id ? notification.vehicle_id : null,
                                        })"
                                    >View</a>
                                    <a v-else-if="['lead', 'leadwidget'].includes(notification.generated_from)"
                                        class="notification__button"
                                        :href="route('dealerships.show', {
                                            route_name: 'services/leads',
                                            dealership: notification.dealership.id,
                                            lead_id: notification.customer_id,
                                        })"
                                    >View</a>
                                    <a v-else
                                        class="notification__button"
                                        :href="route('dealerships.show', {
                                            route_name: 'services/recent',
                                            dealership: notification.dealership.id,
                                            customer_id: notification.customer_id,
                                            auto_campaign_id: notification.auto_campaign_id,
                                            sms_date_time: notification.date,
                                        })"
                                    >View</a>
                                </template>
                                <button
                                    @click="dismissSMSNotifications({ ids: [notification.id], selectedDealerId: selectedDealerId })"
                                    class="notification__button"
                                    :disabled="isLoadingGlobal.getSMSNotifications"
                                    type="button"
                                >Dismiss</button>
                            </div>
                            <div class="notification__date">
                                {{
                                    $moment.utc(notification.date).local()
                                    .format('MM/DD/YY h:mm A')
                                }}
                            </div>
                        </div>
                        <!-- Case Notifications -->
                        <div
                            v-else-if="notification.notificationType === 'case'"
                            :key="'case_'+notification.id"
                            class="notification"
                            :class="{'unread': !notification.read_at}"
                        >

                            <div class="notification__title">
                                Case Update
                            </div>
                            <div class="notification__content">
                                <strong>{{ notification.customer_name }}</strong><br>
                                <template v-if="notification.subject"><strong>{{ notification.subject }}</strong><br></template>
                                <template v-if="notification.description">{{ notification.description }}</template>
                            </div>
                            <div v-if="userSession && userSession.dealership && userSession.dealership.dealer_number !== notification.dealership.dealer_number"
                                class="notification__dealership"
                            >{{ notification.dealership.name}} ({{ notification.dealership.dealer_number }})</div>
                            <div class="notification__buttons">
                                <button
                                    @click="markCaseNotificationsRead({
                                        ids: [notification.id],
                                        selectedDealerId: selectedDealerId,
                                        undo: Boolean(notification.read_at),
                                    })"
                                    :disabled="isLoadingGlobal.getCaseNotifications"
                                    type="button"
                                    class="notification__button"
                                >Mark {{ notification.read_at ? 'Unread' : 'Read'}}</button>
                                <router-link
                                    v-if="userSession && userSession.dealership && userSession.dealership.dealer_number === notification.dealership.dealer_number"
                                    class="notification__button"
                                    :to="{ name: 'services-cases', query: { customer_id: notification.customer_id, vehicle_id: notification.vehicle_id, case_id: notification.case_id }}"
                                >View</router-link>
                                <a v-else
                                    class="notification__button"
                                    :href="route('dealerships.show', { route_name: 'services/recent', dealership: notification.dealership.id, customer_id: notification.customer_id, vehicle_id: notification.vehicle_id, case_id: notification.case_id })"
                                >View</a>
                                <button
                                    @click="dismissCaseNotifications({ ids: [notification.id], selectedDealerId: selectedDealerId })"
                                    class="notification__button"
                                    :disabled="isLoadingGlobal.getSMSNotifications"
                                    type="button"
                                >Dismiss</button>
                            </div>
                            <div class="notification__date">
                                {{ $moment(notification.date).format('MM/DD/YY h:mm A') }}
                            </div>
                        </div>
                        <!-- Chat Notifications -->
                        <div
                            v-else-if="notification.notificationType === 'chat'"
                            :key="'chat_'+notification.id"
                            class="notification"
                            :class="{'unread': !notification.read_at}"
                        >
                            <div class="notification__title">
                                Chat Update
                            </div>
                            <div class="notification__content">
                                <p>{{ $_generalMixin_getASMByIdOrNumber(notification.last_participant) ? $_generalMixin_getASMByIdOrNumber(notification.last_participant).asmName.trim() : notification.last_participant }}</p>
                                <div class="chats-messages">
                                    <div v-for="(message, index) in notification.latest_messages.slice(0, 10)" :key="index" class="chats-messages__message">
                                        {{ truncate(message, 38, '…') }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="userSession && userSession.dealership && userSession.dealership.dealer_number !== notification.dealership.dealer_number"
                                class="notification__dealership"
                            >
                                {{ notification.dealership.name}} ({{ notification.dealership.dealer_number }})
                            </div>
                            <div class="notification__buttons">
                                <button
                                    @click="markChatNotificationsRead({
                                        ids:[notification.id],
                                        selectedDealerId: selectedDealerId,
                                        undo: Boolean(notification.read_at),
                                    })"
                                    :disabled="isLoadingGlobal.getChatNotifications"
                                    type="button"
                                    class="notification__button"
                                >Mark {{ notification.read_at ? 'Unread' : 'Read'}}</button>
                                <router-link v-if="userSession && userSession.dealership && userSession.dealership.dealer_number === notification.dealership.dealer_number"
                                    class="notification__button"
                                    :to="{ name: 'chats-ui', query: { conversation_id: notification.conversation_id }}"
                                >View</router-link>
                                <a v-else
                                    class="notification__button"
                                    :href="route('dealerships.show', { route_name: 'chats/ui', dealership: notification.dealership.id, conversation_id: notification.conversation_id, participants: notification.participants.join(',') })"
                                >View</a>
                                <button
                                    @click="dismissChatNotifications({ ids: [notification.id], selectedDealerId: selectedDealerId })"
                                    class="notification__button"
                                    :disabled="isLoadingGlobal.getChatNotifications"
                                    type="button"
                                >Dismiss</button>
                            </div>
                            <div class="notification__date">
                                {{ $moment.utc(notification.last_message_date).local().format('MM/DD/YY h:mm A') }}
                            </div>
                        </div>
                    </template>
                </transition-group>
            </template>
        </div>

        <ToastCustom ref="toastCustom" />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { preference } from 'vue-preferences';
import { truncate } from '@/filters/stringFormats';
import ToastCustom from "@/components/notifications/ToastCustom.vue";
import { generalMixin } from '@/mixins/generalMixin';

export default {
    mixins: [generalMixin],

    components: {
        ToastCustom,
    },

    props: {
        areCaseNotificationsEnabled: {
            type: Boolean,
            default: true,
        },
        areChatNotificationsEnabled: {
            type: Boolean,
            default: true,
        },
        arePendingMessageNotificationsEnabled: {
            type: Boolean,
            default: true,
        },
        areSmsNotificationsEnabled: {
            type: Boolean,
            default: true,
        },
        badge: {
            type: String,
        },
        dealershipUser: {
            type: Object,
        },
        icon: {
            type: String,
        },
        notificationSound: {
            type: String,
        },
    },

    data() {
        return {
            isGetNotificationsThrottled: false,
            isNotificationSoundThrottled: false,

            getNotificationsThrottleInterval: null,

            showAllDealershipNotifications: true,
        }
    },

    computed: {
        ...mapState('global', {
            isLoadingGlobal: 'isLoading',
        }),

        ...mapGetters('global', {
            allNotifications: 'allNotifications',
            hasUnreadNotifications: 'hasUnreadNotifications',
        }),

        ...mapState('userSession', {
            userSession: 'userSession',
        }),

        areNotificationsMuted: preference('areNotificationsMuted'),

        isGettingNotifications() {
            return (
                this.isLoadingGlobal.getSMSNotifications ||
                this.isLoadingGlobal.getCaseNotifications ||
                this.isLoadingGlobal.getChatNotifications
            );
        },

        isMarkingNotificationsRead() {
            return (
                this.isLoadingGlobal.markSMSNotificationsRead ||
                this.isLoadingGlobal.markCaseNotificationsRead ||
                this.isLoadingGlobal.markChatNotificationsRead
            );
        },

        isDismissingNotifications() {
            return (
                this.isLoadingGlobal.dismissSMSNotifications ||
                this.isLoadingGlobal.dismissCaseNotifications ||
                this.isLoadingGlobal.dismissChatNotifications
            );
        },

        allNotificationsSortAndFiltered() {
            const sortAndFilteredNotifications = {
                notifications: [],
                total: this.allNotifications.total,
            };

            sortAndFilteredNotifications.notifications = this.allNotifications.notifications.filter(notification => {
                return !this.showAllDealershipNotifications
                    ? (
                        this.userSession
                        && this.userSession.dealership.dealer_number === notification.dealership.dealer_number
                    )
                    : true;
            });

            return sortAndFilteredNotifications;
        },

        ///////////////////////////////////////////////////////////////////////
        // Getter/Setters
        ///////////////////////////////////////////////////////////////////////

        showNotificationsTray: {
            get() {
                return this.$store.state.navigation.showNotificationsTray;
            },
            set(value) {
                this.$store.commit('navigation/SET_NOTIFICATIONS_TRAY', value);
            }
        },
    },

    watch: {
        // Inform the user what the setting they are changing does
        showAllDealershipNotifications(value) {
            this.$message({
                type: 'info',
                message: value
                    ? 'Showing all dealership notifications'
                    : 'Showing only current dealership notifications',
            });
        },

        // Inform the user what the setting they are changing does
        areNotificationsMuted(value) {
            this.$message({
                type: 'info',
                message: value
                    ? 'Pop-up notifications have been muted'
                    : 'Pop-up notifications have been unmuted',
            });
        },
    },

    created() {
        this.getNotifications().then(() => this.addGeneralToast());

        // Listen for Case notifications updated event.
        Echo
            .private(`App.Models.User.${this.dealershipUser.id}`)
            .listen('CaseNotificationsUpdatedEvent', () => {
                if (!this.isGetNotificationsThrottled) {
                    this.getNotifications().then(() => this.addGeneralToast());
                }
            });

        // Listen for Chat notification event.
        Echo
            .private(`App.Models.User.${this.dealershipUser.id}`)
            .listen('ChatNotificationEvent', (event) => {
                this.pushChatNotification(event);
            });

        // Listen for SMS notification event.
        Echo
            .private(`App.Models.User.${this.dealershipUser.id}`)
            .listen('SmsNotificationEvent', (event) => {
                this.pushSmsNotification(event);

                this.$titleManager.flash(
                    'New SMS Message',
                    {
                        duration: 0,
                        interval: 2,
                    }
                );

                if (!this.areNotificationsMuted && !this.showNotificationsTray) {
                    this.$refs.toastCustom.addToast('Text', event, 'sms');
                    this.playNotificationSound();
                }
            });
    },

    methods : {
        // Vuex global actions
        ...mapActions('global', {
            dismissCaseNotifications: 'dismissCaseNotifications',
            dismissChatNotifications: 'dismissChatNotifications',
            dismissSMSNotifications: 'dismissSMSNotifications',
            getCaseNotifications: 'getCaseNotifications',
            getChatNotifications: 'getChatNotifications',
            getSMSNotifications: 'getSMSNotifications',
            markAllCaseNotificationsRead: 'markAllCaseNotificationsRead',
            markAllChatNotificationsRead: 'markAllChatNotificationsRead',
            markAllSMSNotificationsRead: 'markAllSMSNotificationsRead',
            markCaseNotificationsRead: 'markCaseNotificationsRead',
            markChatNotificationsRead: 'markChatNotificationsRead',
            markSMSNotificationsRead: 'markSMSNotificationsRead',
            pushChatNotification: 'pushChatNotification',
            pushSmsNotification: 'pushSmsNotification',
        }),

        async getNotifications() {
            this.isGetNotificationsThrottled = true;

            clearInterval(this.getNotificationsThrottleInterval);

            this.getNotificationsThrottleInterval = setInterval(() => {
                this.isGetNotificationsThrottled = false;
            }, 20 * 1000);

            await Promise.all([
                this.areSmsNotificationsEnabled || this.arePendingMessageNotificationsEnabled
                    ? this.getSMSNotifications(this.selectedDealerId)
                    : null,
                this.areCaseNotificationsEnabled ? this.getCaseNotifications(this.selectedDealerId) : null,
                this.areChatNotificationsEnabled ? this.getChatNotifications(this.selectedDealerId) : null,
            ]);
        },

        addGeneralToast() {
            if (this.allNotifications.notifications?.length) {
                const messageCount = this.allNotifications.notifications.length;
                const message = `You have ${messageCount} ${
                    messageCount > 1
                        ? 'notifications'
                        : 'notification'
                } available`;

                this.$refs.toastCustom.addToast('New Notifications', message);
                this.playNotificationSound();
                this.$titleManager.flash(
                    'New Notifications',
                    {
                        duration: 0,
                        interval: 2,
                    }
                );
            }
        },

        pushNotify(data) {
            const title = 'Bolt On Technology®';

            if (!("Notification" in window)) {
                console.info("This browser does not support desktop notification");
            } else if (window.Notification.permission === "granted") {
                new Notification(title, {
                    body: data.message,
                    icon: this.icon,
                    badge: this.badge,
                    vibrate: [200, 100, 200],
                    tag: 'NotificationEvent'
                });
            } else if (window.Notification.permission !== 'denied' || window.Notification.permission === "default") {
                window.Notification.requestPermission(function (permission) {
                    if (permission === "granted") {
                        new window.Notification(title, {
                            body: data.message,
                            icon: this.icon,
                            badge: this.badge,
                            tag: 'NotificationEvent'
                        });
                    }
                });
            }
        },

        /**
         * Clear shown notifications
         */
        async clearDisplayedNotifications() {
            const smsNotificationIds = this.allNotificationsSortAndFiltered.notifications.reduce((notificationIds, notification) => {
                if (notification.notificationType === 'sms') {
                    notificationIds.push(notification.id);
                }
                return notificationIds;
            }, []);

            const caseNotificationIds = this.allNotificationsSortAndFiltered.notifications.reduce((notificationIds, notification) => {
                if (notification.notificationType === 'case') {
                    notificationIds.push(notification.id);
                }
                return notificationIds;
            }, []);

            const chatNotificationIds = this.allNotificationsSortAndFiltered.notifications.reduce((notificationIds, notification) => {
                if (notification.notificationType === 'chat') {
                    notificationIds.push(notification.id);
                }
                return notificationIds;
            }, []);

            await Promise.all([
                smsNotificationIds.length ? this.markSMSNotificationsRead({ids: smsNotificationIds, selectedDealerId: this.selectedDealerId}) : null,
                caseNotificationIds.length ? this.markCaseNotificationsRead({ids: caseNotificationIds, selectedDealerId: this.selectedDealerId}) : null,
                chatNotificationIds.length ? this.markChatNotificationsRead({ids: chatNotificationIds, selectedDealerId: this.selectedDealerId}) : null,
            ]);
        },

        /**
         * Dismiss shown notifications
         */
        async dismissDisplayedNotifications() {
            // Confirm
            if (
                !confirm(
                    `Are you sure you want to dismiss ${this.allNotificationsSortAndFiltered.notifications.length} of your notifications? This cannot be undone.`
                )
            ) {
                return;
            }

            const smsNotificationIds = this.allNotificationsSortAndFiltered.notifications.reduce((notificationIds, notification) => {
                if (notification.notificationType === 'sms') {
                    notificationIds.push(notification.id);
                }
                return notificationIds;
            }, []);

            const caseNotificationIds = this.allNotificationsSortAndFiltered.notifications.reduce((notificationIds, notification) => {
                if (notification.notificationType === 'case') {
                    notificationIds.push(notification.id);
                }
                return notificationIds;
            }, []);

            const chatNotificationIds = this.allNotificationsSortAndFiltered.notifications.reduce((notificationIds, notification) => {
                if (notification.notificationType === 'chat') {
                    notificationIds.push(notification.id);
                }
                return notificationIds;
            }, []);

            await Promise.all([
                caseNotificationIds.length ? this.dismissCaseNotifications({ids: caseNotificationIds, selectedDealerId: this.selectedDealerId}) : null,
                chatNotificationIds.length ? this.dismissChatNotifications({ids: chatNotificationIds, selectedDealerId: this.selectedDealerId}) : null,
                smsNotificationIds.length ? this.dismissSMSNotifications({ids: smsNotificationIds, selectedDealerId: this.selectedDealerId}) : null,
            ]);
        },

        showToastMessage(title, message) {
            const notification = {
                title: title,
                message: message,
            }

            this.$refs.toastCustom.addToast(notification);

            this.playNotificationSound();

            // Push Notification Browser
            this.pushNotify(notification);
        },

        playNotificationSound() {
            if (this.isNotificationSoundThrottled) {
                return;
            }

            this.isNotificationSoundThrottled = true;

            setTimeout(() => {
                this.isNotificationSoundThrottled = false;
            }, 5000);

            const audio = new Audio(this.notificationSound);

            if (navigator?.userActivation?.hasBeenActive) {
                audio.play().catch(error => {
                    console.error(error);
                });
            }
        },

        truncate,
    },
}

</script>

<style lang="scss" scoped>
    .chats-messages {
        --margin: 2px;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: calc(var(--margin) * -1);

        .chats-messages__message {
            background: var(--charlie_bg--shift);
            border-radius: 5px;
            color: white;
            display: flex;
            flex-grow: 1;
            margin: var(--margin);
            padding: 1px 6px;
        }
    }

    .notifications-sidebar {
        background: var(--delta_bg);
        color: var(--delta_text);
        max-width: 320px;
        overflow: auto;
        padding: 1.2rem;
        width: 100%;
    }

    .notification {
        --gap: 15px;
        --indicator-size: 11px;

        background: var(--delta_bg--shift);
        border-radius: 4px;
        display: flex;
        flex-flow: column;
        gap: var(--gap);
        margin-bottom: var(--gap);
        padding: var(--gap);
        position: relative;

        &.unread {
            .notification__title {
                &:before {
                    background: var(--alpha_indicator-color-1);
                    border-radius: 50%;
                    content: '';
                    display: inline-block;
                    height: var(--indicator-size);
                    margin-right: 3px;
                    width: var(--indicator-size);
                }
            }
        }

        .notification__title {
            align-items: center;
            display: flex;
            font-weight: bold;
        }


        .notification__dealership {
            align-self: center;
            background: var(--charlie_bg--shift);
            padding: 2px 15px;
        }

        .notification__buttons {
            border-top: 1px solid var(--charlie_border);
            display: flex;
            justify-content: space-between;
            padding-top: var(--gap);
        }

        .notification__button {
            background: none;
            border: none;
            color: var(--charlie_text);
            cursor: pointer;
            font-weight: bold;
            padding: 0;

            &:hover {
                border-bottom: 1px solid;
                margin-bottom: -1px;
                text-decoration: none;
            }
        }

        .notification__date {
            font-size: .8em;
            position: absolute;
            right: var(--gap);
            top: var(--gap);
        }
    }

    .notification-transition-enter-active {
        animation: fade-in .5s ease-out both;
    }

    .notification-transition-leave-active {
        animation: fade-in .5s reverse ease-in both;
    }

    @keyframes fade-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.25);
        }
        100% {
            transform: scale(1);
        }
    }

</style>
