<template>
    <div
        class="chat-message"
        :class="[
            sms.direction === 'Incoming' ? 'chat-message--incoming' : 'chat-message--outgoing',
            {'chat-message--deleted': sms.status === 'Deleted'}
        ]"
    >
        <div class="chat-message__user-icon" v-tooltip:[tooltipPlacement] :title="getSenderInfoFromSMS(sms).displayName">
            {{ getSenderInfoFromSMS(sms).initials }}
        </div>
        <div class="chat-message__bubble-wrapper">
            <div class="chat-message__bubble">
                <span v-if="sms.text" v-html="convertUrlsToHtml({text: sms.text, target: '_blank', style: 'color: inherit'})"></span>
                <small v-else><em>(No data to display)</em></small>
            </div>
        </div>
        <div
            v-if="sms.status !== 'Deleted'"
            class="chat-message__caption"
        >
            <i v-if="sms.twilio_status === 'Undelivered'"
                class="fas fa-exclamation-circle text-danger mr-1"
            ></i>

            <template v-if="sms.twilio_status === 'Undelivered'">
                {{ `${sms.twilio_status}: ${sms.twilio_error_message ? sms.twilio_error_message : 'Unknown error'}` }}
            </template>
            <template v-else-if="sms.generated_from === 'Comunicator'">
                Sent by
                {{
                    sms.first_name_ASM || sms.last_name_ASM
                    ? `${sms.first_name_ASM} ${sms.last_name_ASM}`
                    : 'Comunicator'
                }}
            </template>
            <template v-else-if="sms.generated_from === 'System'">
                System Generated
            </template>
            <template v-else>
                Received via {{ sms.generated_from }}
            </template>
            • {{ $moment(sms.DateTime).format('MM/DD/YY h:mm A') }} 
            <template v-if="sms.direction === 'Incoming' && sms.from_number">
                {{ formatPhoneNumber(sms.from_number) }}
                {{ getPhoneNumberLabel(sms.from_number) }}
            </template>
            <template v-else-if="sms.direction === 'Outgoing' && sms.send_to_phone">
                {{ formatPhoneNumber(sms.send_to_phone) }}
                {{ getPhoneNumberLabel(sms.send_to_phone) }}
            </template>
            <template v-if="!sms.vehicle_id">
                | No associated vehicle
            </template>
        </div>
        <div
            v-else
            class="chat-message__caption"
        >
            <i class="fa-solid fa-trash"></i>
            Message Deleted • {{ $moment(sms.DateTime).format('MM/DD/YY h:mm A') }}
        </div>
    </div>
</template>

<script>
import { convertUrlsToHtml } from '@/filters/stringFormats';
import { formatPhoneNumber } from '@/filters/phoneNumbers';

export default {
    props: {
        customerInitials: {
            type: String,
            require: true,
        },

        selectedCustomer: {
            type: Object,
            required: true,
        },

        sms: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            //
        }
    },

    computed: {
        tooltipPlacement() {
            return this.sms.direction === 'Incoming' ? 'right' : 'left';
        },
    },

    methods: {
        /**
         * Imported methods
         */
        convertUrlsToHtml,
        formatPhoneNumber,

        /**
         * Get the phone number label if it exists
         * 
         * @param {string} phoneNumber
         * @returns {string}
         */
        getPhoneNumberLabel(phoneNumber) {
            let label = '';

            const selectedCustomerPhoneNumbers = [
                {
                    label: this.selectedCustomer.cell_phone_label,
                    number: this.selectedCustomer.cellPhone,
                },
                {
                    label: this.selectedCustomer.home_phone_label,
                    number: this.selectedCustomer.homePhone,
                },
                {
                    label: this.selectedCustomer.work_phone_label,
                    number: this.selectedCustomer.workPhone,
                },
            ];

            // Set label to phone number label if it exists
            selectedCustomerPhoneNumbers.forEach(customerPhoneNumber => {
                if (!customerPhoneNumber.label) {
                    return;
                }

                if (!(customerPhoneNumber.number && phoneNumber)) {
                    return;
                }

                if (this.formatPhoneNumber(phoneNumber) === this.formatPhoneNumber(customerPhoneNumber.number)) {
                    label = customerPhoneNumber.label ? `(${customerPhoneNumber.label})` : '';
                }
            });

            return label;
        },

        /**
         * Get the sender's info from SMS
         */
        getSenderInfoFromSMS(sms) {
            const senderInfo = {
                initials: '',
                displayName: '',
            };

            if (sms.direction === 'Incoming') {
                senderInfo.initials = this.customerInitials;
                senderInfo.displayName = `${this.selectedCustomer?.firstName ? this.selectedCustomer.firstName : ''} ${this.selectedCustomer?.lastName ? this.selectedCustomer.lastName : ''}`;
            } else if (sms.direction === 'Outgoing') {
                senderInfo.initials = sms.first_name_ASM || sms.last_name_ASM ? sms.first_name_ASM.charAt(0) + sms.last_name_ASM.charAt(0) : 'B';
                senderInfo.displayName = sms.first_name_ASM || sms.last_name_ASM ? `${sms.first_name_ASM} ${sms.last_name_ASM}` : 'Bolt On Tech';
            }

            return senderInfo;
        },
    },
}
</script>
