<template>
    <div
        class="chat-message"
        :class="[
            messageDirection === 'incoming' ? 'chat-message--incoming' : 'chat-message--outgoing',
            {'chat-message--deleted': sms.status === 'Deleted'}
        ]"
    >
        <div class="chat-message__user-icon" :title="getSenderInfoFromSMS().displayName">
            {{ getSenderInfoFromSMS().initials }}
        </div>
        <div class="chat-message__bubble-wrapper">
            <div class="chat-message__bubble">
                <span v-if="sms.description" v-html="convertUrlsToHtml({text: sms.description, target: '_blank', style: 'color: inherit'})"></span>
                <small v-else><em>(No data to display)</em></small>
            </div>
            <div
                v-if="sms.status !== 'Deleted'"
                class="chat-message__caption"
            >
                <i class="icon mr-1">send</i>
                <template v-if="messageDirection === 'outgoing'">
                    Sent By {{ getSenderInfoFromSMS().displayName }}
                </template>
                <template v-else>
                    Sent From {{ customerInfo.first_name }} {{ customerInfo.last_name }}
                </template>
                • {{ $moment.utc(sms.date_modified).local().format('MM/DD/YY h:mm A') }}
            </div>
            <div
                v-else
                class="chat-message__caption"
            >
                <i class="fa-solid fa-trash"></i>
                Message Deleted • {{ $moment(sms.date_modified).format('MM/DD/YY h:mm A') }}
            </div>
        </div>
    </div>
</template>

<script>
import { convertUrlsToHtml } from '@/filters/stringFormats';
import { generalMixin } from '@/mixins/generalMixin';

export default {
    mixins: [generalMixin],

    props: {
        customerInfo: {
            type: Object,
            required: true,
        },

        sms: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            //
        }
    },

    computed: {
        asm() {
            return this.sms.modified_user_id ? this.$_generalMixin_getASMByIdOrNumber(this.sms.modified_user_id) : null;
        },

        customerInitials() {
            const firstInitial = this.customerInfo.first_name ? this.customerInfo.first_name.charAt(0) : '';
            const lastInitial = this.customerInfo.last_name ? this.customerInfo.last_name.charAt(0) : '';
            const initials = firstInitial || lastInitial ? firstInitial + lastInitial : 'N/A';

            return initials;
        },

        messageDirection() {
            let direction = 'incoming';

            if (this.sms.modified_user_id) {
                direction = 'outgoing';
            }

            return direction;
        },
    },

    methods: {
        /**
         * Imported methods
         */
        convertUrlsToHtml,

        /**
         * Get the sender's info from SMS
         */
        getSenderInfoFromSMS() {
            const senderInfo = {
                initials: '',
                displayName: '',
            };

            if (this.messageDirection === 'incoming') {
                senderInfo.initials = this.customerInitials;
                senderInfo.displayName = `${this.customerInfo?.first_name ? this.customerInfo.first_name : ''} ${this.customerInfo?.last_name ? this.customerInfo.last_name : ''}`;
            } else if (this.messageDirection === 'outgoing') {
                senderInfo.initials = this.asm?.first_name || this.asm?.last_name ? this.asm.first_name.charAt(0) + this.asm.last_name.charAt(0) : 'B';
                senderInfo.displayName = this.asm?.first_name || this.asm?.last_name ? `${this.asm.first_name} ${this.asm.last_name}` : 'Bolt On Tech';
            }

            return senderInfo;
        },
    },
}
</script>
