<template>
<div>
    <div class="top-bar">
        <!-- Hamburger Menu Button -->
        <button
            @click="showExpandedNav = !showExpandedNav"
            type="button"
            class="top-bar__menu-button"
        >
            <i class="fa-solid fa-bars top-bar__menu-icon"></i>
        </button>

        <!-- Logo -->
        <div class="top-bar__logo-wrapper">
            <img
                src="../../../images/logo.svg"
                class="top-bar__logo"
                alt="Unotifi"
                title="Unotifi"
                width="100%"
            >
        </div>

        <!-- Links -->
        <div class="top-bar__nav-links">
            <router-link
                :to="{ name: 'services' }"
                active-class="active"
            >SERVICE</router-link>

            <router-link
                :to="{ name: 'analytics' }"
                active-class="active"
            >ANALYTICS</router-link>
        </div>

        <!-- Manual Payment Button -->
        <button
            v-if="hasAutoMerchantAccount"
            @click="$refs.modalAddManualPayment.showModal()"
            type="button"
            class="top-bar__manual-payment-button"
        >
            <i class="fas fa-sm fa-plus mr-1" style="margin-top: 2px"></i>PAYMENT REQUEST
        </button>

        <!-- Spacer -->
        <div class="top-bar__spacer"></div>

        <!-- Dealership Select -->
        <div class="top-bar__dealership-select">
            <div v-if="dealerships.length > 1" class="dropdown">
                <button
                    class="btn dropdown-toggle text-charlie_text"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    {{ selectedDealer ? truncate(selectedDealer.name, 28, '…') : 'Switch Dealership' }}
                </button>
                <div
                    class="dropdown-menu dropdown-menu-right dealership-select"
                    aria-labelledby="dropdownMenuButton"
                >
                    <h6 class="dropdown-header">Recent Dealerships</h6>
                    <a
                        v-for="(dealership, index) in previouslyAccessedDealerships"
                        :href="route('dealerships.show', { dealership: dealership.id, urlHash: urlHash })"
                        class="dropdown-item"
                        :key="`${index}-${dealership.id}`"
                    >
                        {{ dealership.name }} ({{ dealership.dealer_number }})
                    </a>
                    <div v-if="unaccessedDealerships.length" class="dropdown-divider"></div>
                    <a
                        v-for="(dealership, index) in unaccessedDealerships"
                        :href="route('dealerships.show', { dealership: dealership.id, urlHash: urlHash })"
                        class="dropdown-item"
                        :class="{ 'font-weight-bold': dealership.last_accessed_date }"
                        :key="`${index}-${dealership.id}`"
                    >
                        {{ dealership.name }} ({{ dealership.dealer_number }})
                    </a>
                </div>
            </div>

            <span v-else class="text-charlie_text w-100 text-center p-2">
                {{ selectedDealer ? selectedDealer.name : '' }}
            </span>
        </div>

        <!-- Action Buttons -->
        <div class="top-bar__action-buttons">
            <button
                type="button"
                class="profile notify-alpha_indicator-color-1"
                :class="{notify: missingUserData.length}"
                :style="`background-image: url(${backgroundImage})`"
                data-toggle="dropdown"
                id="dropdownMenuProfile"
                v-tooltip:bottom
                :title="dealershipUser?.name || 'Profile'"
            ></button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuProfile">
                <template v-if="missingUserData.length">
                    <router-link
                        v-if="missingUserData.includes('email')"
                        :to="{ name: 'settings-account-settings' }"
                        class="dropdown-item missing-user-data"
                    >Add a Recovery Email</router-link>
                    <router-link
                        v-if="missingUserData.includes('verified-email')"
                        :to="{ name: 'settings-account-settings' }"
                        class="dropdown-item missing-user-data"
                    >Verify Your Email Address</router-link>
                    <div class="dropdown-divider p-0"></div>
                </template>
                <button type="button" class="dropdown-item" @click="$refs.modalAwayMessage.showModal()">
                    Set Away Message
                </button>
                <router-link :to="{ name: 'settings-account-settings' }" class="dropdown-item">
                    Settings
                </router-link>
                <div class="dropdown-divider p-0"></div>
                <button type="button" class="dropdown-item" @click="$refs.modalSupport.showModal()">
                    Support
                </button>
                <div class="dropdown-divider p-0"></div>
                <a
                    class="dropdown-item"
                    :href="route('logout')"
                    onclick="event.preventDefault();document.getElementById('logout-form').submit();"
                >Logout</a>
                <form id="logout-form" :action="route('logout')" method="POST" style="display: none;">
                    <CSRFTokenInput />
                </form>
            </div>

            <button
                @click="showNotificationsTray = !showNotificationsTray"
                type="button"
                class="top-bar__action-button"
                :class="{
                    notify: hasUnreadNotifications,
                    blink: isLoadingGlobal.getSMSNotifications
                }"
            >
                <i
                    class="fa-solid fa-bell"
                    :class="{
                        'fa-shake': hasRecentlyAddedNotifications
                    }"
                ></i>
            </button>

            <button type="button" id="beamer_button" class="beamer-notifications top-bar__action-button">
                <i class="fas fa-gift"></i>
            </button>

            <button @click="cycleTheme" type="button" class="top-bar__action-button">
                <i v-if="applicationTheme === 'dark'" class="fas fa-moon"></i>
                <i v-else-if="applicationTheme === 'auto'" class="fa-solid fa-moon-over-sun"></i>
                <i v-else class="fas fa-sun"></i>
            </button>
        </div>
    </div>

    <!-- Add Payment Modal -->
    <ModalAddManualPayment v-if="hasAutoMerchantAccount" ref="modalAddManualPayment" />

    <!-- Away Message Modal -->
    <ModalAwayMessage ref="modalAwayMessage" />

    <!-- Support Modal -->
    <ModalSupport ref="modalSupport" />
</div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { Notification } from "element-ui";
import CSRFTokenInput from "@/components/CSRFTokenInput.vue";
import ModalAddManualPayment from "@/components/modals/ModalAddManualPayment.vue";
import ModalAwayMessage from "@/components/modals/ModalAwayMessage.vue";
import ModalSupport from "@/components/modals/ModalSupport.vue";
import { preference } from 'vue-preferences';
import { truncate } from '@/filters/stringFormats';

export default {
    components: {
        CSRFTokenInput,
        ModalAddManualPayment,
        ModalAwayMessage,
        ModalSupport,
    },

    props: {
        backgroundImage: {
            type: String,
        },
        dealershipUser: {
            type: Object,
        },
        dealerships: {
            type: Array,
        },
    },

    data() {
        return {
            hasRecentlyAddedNotifications: false,
            isWarningUser: false,
            onLine: navigator.onLine,
            selectedDealer: null,
            urlHash: location.hash,
        }
    },

    computed: {
        ...mapState('global', {
            isLoadingGlobal: 'isLoading',
        }),

        ...mapGetters('global', {
            allNotifications: 'allNotifications',
            hasUnreadNotifications: 'hasUnreadNotifications',
        }),

        ...mapState('userSession', {
            userSession: 'userSession',
        }),

        applicationTheme: preference('applicationTheme', { defaultValue: 'light' }),

        hasAutoMerchantAccount() {
            return Boolean(this.userSession?.hasAutoMerchantAccount);
        },

        // Previously accessed dealerships
        previouslyAccessedDealerships() {
            return this.dealerships.filter(dealership => dealership.last_accessed_date);
        },

        // Unaccessed dealerships
        unaccessedDealerships() {
            return this.dealerships.filter(dealership => !dealership.last_accessed_date);
        },

        // Missing user data
        missingUserData() {
            const missingData = [];
            if (this.userSession && !this.userSession?.userInfo.email) {
                missingData.push('email');
            }

            if (this.userSession && this.userSession?.userInfo.email && !this.userSession?.hasVerifiedEmail) {
                missingData.push('verified-email');
            }

            return missingData;
        },

        ///////////////////////////////////////////////////////////////////////
        // Getter/Setters
        ///////////////////////////////////////////////////////////////////////

        showExpandedNav: {
            get() {
                return this.$store.state.navigation.showExpandedNav;
            },
            set(value) {
                this.$store.commit('navigation/SET_EXPANDED_NAV', value);
            }
        },

        showNotificationsTray: {
            get() {
                return this.$store.state.navigation.showNotificationsTray;
            },
            set(value) {
                this.$store.commit('navigation/SET_NOTIFICATIONS_TRAY', value);
            }
        },
    },

    watch: {
        $route() {
            this.urlHash = location.hash;
        },

        allNotifications(newValue, oldValue) {
            if (newValue.notifications.length > oldValue.notifications.length) {
                this.hasRecentlyAddedNotifications = true;

                setTimeout(() => {
                    this.hasRecentlyAddedNotifications = false;
                }, 500);
            }
        },

        applicationTheme(value) {
            // Remove any themes from body
            const regx = new RegExp('\\b' + 'theme_' + '[^ ]*[ ]?\\b', 'g');
            document.body.className = document.body.className.replace(regx, '');

            // Add selected theme to body
            if (value) {
                document.body.classList.add(`theme_${value}`);
            }
        },
    },

    created() {
        // Set the selected dealership
        this.selectedDealer = this.dealerships.find(dealership => dealership.id == this.selectedDealerId);

        // Perform mixpanel actions
        if (this.selectedDealer && this.dealershipUser) {
            this.$mixpanel.identify(this.dealershipUser.id);
            this.$mixpanel.people.set({
                $name: this.dealershipUser.name,
                dealer_name: this.selectedDealer.name,
                dealer_number: this.selectedDealer.dealer_number,
                dealer_username: this.dealershipUser.username,
                date: this.$moment(new Date()).format('YYYY-MM-DD h:mm:ss'),
            });
        }

        if (!this.userSession?.userInfo.name || !this.userSession?.userInfo.email) {
            this.isWarningUser = true;
        }
    },

    mounted() {
        window.addEventListener('online', this.updateIsOnline);
        window.addEventListener('offline', this.updateIsOnline);
    },


    methods: {
        /**
         * Imported methods
         */
        truncate,

        // Inform  user if lost internet connection
        updateIsOnline(e){
            const { type } = e;
            let params = [];

            if (type === 'online') {
                params = {
                    title: 'Bolt On Technology®',
                    message: 'Network connection is available',
                    type: 'success',
                    duration: 0,
                }
            } else {
                params = {
                    title: 'Bolt On Technology®',
                    message: 'No network connection available!',
                    type: 'warning',
                    duration: 0,
                }
            }

            Notification(params);
        },

        cycleTheme() {
            switch (this.applicationTheme) {
                case 'light':
                    this.applicationTheme = 'dark';
                    break;
                case 'dark':
                    this.applicationTheme = 'auto';
                    break;
                default:
                    this.applicationTheme = 'light';
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.dealership-select {
    max-height: 200px;
    overflow: auto;
}

.missing-user-data::before {
    content: '\f06a';
    font-family: 'Font Awesome 5 Pro', serif;
    margin-right: 5px;
}
</style>

<style lang="scss">
#beamer_button {
    font-size: 20px;
    position: relative;

    &.beamer-has-new-activity::after {
        background: var(--alpha_indicator-color-1);
        border: 2px solid var(--charlie_bg);
        border-radius: 50%;
        content: '';
        height: 10px;
        position: absolute;
        right: 0px;
        top: -2px;
        width: 10px;
    }
}
</style>
