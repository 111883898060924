<template>
    <div class="d-flex justify-content-between align-items-center border-bottom flex-shrink-0">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a
                    @click="$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', timeout: 250}), updateSelectedTabId('all')"
                    class="nav-link"
                    :class="{active: defaultTab === 'all'}"
                    id="all-tab"
                    data-toggle="tab"
                    href="#all"
                    role="tab"
                    aria-controls="all"
                    aria-selected="false"
                >All</a>
            </li>
            <li class="nav-item">
                <a
                    @click="$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', timeout: 250}), updateSelectedTabId('messages')"
                    class="nav-link"
                    :class="{active: defaultTab === 'messages'}"
                    id="messages-tab"
                    data-toggle="tab"
                    href="#messages"
                    role="tab"
                    aria-controls="messages"
                    aria-selected="true"
                >Messages</a>
            </li>
            <li v-if="optionalTabs.includes('pending')" class="nav-item">
                <a
                    @click="$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', timeout: 250}), updateSelectedTabId('pending')"
                    class="nav-link"
                    :class="{active: defaultTab === 'pending'}"
                    id="pending-tab"
                    data-toggle="tab"
                    href="#pending"
                    role="tab"
                    aria-controls="pending"
                    aria-selected="false"
                >Pending
                    <span
                        v-if="messagesPendingApprovalLength"
                        class="badge badge-pill badge-alpha_primary"
                    >
                        {{ messagesPendingApprovalLength }}
                    </span>
                </a>
            </li>
            <li v-if="userSession && userSession.hasCallTrackingIntegration" class="nav-item">
                <a
                    @click="$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', position: 'top', timeout: 250}), updateSelectedTabId('phone-calls')"
                    class="nav-link"
                    :class="{active: defaultTab === 'phone-calls'}"
                    id="phone-calls-tab"
                    data-toggle="tab"
                    href="#phone-calls"
                    role="tab"
                    aria-controls="phone-calls"
                    aria-selected="true"
                >
                    Phone Call Logs
                    <span
                        v-if="isLoadingServicesInfo.getPhoneCallLogs"
                        class="badge badge-pill badge-alpha_primary"
                    >
                        <i class="fas fa-circle-notch fa-spin"></i>
                    </span>
                </a>
            </li>
            <li v-if="optionalTabs.includes('cases')" class="nav-item">
                <a
                    @click="$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', timeout: 250}), updateSelectedTabId('cases')"
                    class="nav-link"
                    :class="{active: defaultTab === 'cases'}"
                    id="cases-tab"
                    data-toggle="tab"
                    href="#cases"
                    role="tab"
                    aria-controls="cases"
                    aria-selected="false"
                >Cases</a>
            </li>
            <li v-if="optionalTabs.includes('case-info')" class="nav-item">
                <a
                    @click="$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', timeout: 250}), updateSelectedTabId('case-info')"
                    class="nav-link"
                    :class="{active: defaultTab === 'case-info'}"
                    id="case-info-tab"
                    data-toggle="tab"
                    href="#case_info"
                    role="tab"
                    aria-controls="case_info"
                    aria-selected="false"
                >Case Info</a>
            </li>
            <li class="nav-item">
                <a
                    @click="$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', timeout: 250}), updateSelectedTabId('opportunities')"
                    class="nav-link"
                    :class="{active: defaultTab === 'opportunities'}"
                    id="opportunities-tab"
                    data-toggle="tab"
                    href="#opportunities"
                    role="tab"
                    aria-controls="opportunities"
                    aria-selected="false"
                >Opportunities</a>
            </li>
            <li class="nav-item">
                <a
                    @click="$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', timeout: 250}), updateSelectedTabId('notes')"
                    class="nav-link"
                    :class="{active: defaultTab === 'notes'}"
                    id="notes-tab"
                    data-toggle="tab"
                    href="#notes"
                    role="tab"
                    aria-controls="notes"
                    aria-selected="false"
                >
                    Notes
                    <span
                        v-if="isLoadingServicesInfo.getVehicleNotesById"
                        class="badge badge-pill badge-alpha_primary"
                    >
                        <i class="fas fa-circle-notch fa-spin"></i>
                    </span>
                    <span
                        v-else-if="errors.getVehicleNotesById"
                        class="badge badge-pill badge-alpha_danger"
                    >
                        <i class="fas fa-exclamation-triangle"></i>
                    </span>
                </a>
            </li>
            <li class="nav-item">
                <a
                    @click="$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', timeout: 250}), updateSelectedTabId('communication')"
                    class="nav-link"
                    :class="{active: defaultTab === 'communication'}"
                    id="communication-tab"
                    data-toggle="tab"
                    href="#communication"
                    role="tab"
                    aria-controls="communication"
                    aria-selected="false"
                >Automated Communication</a>
            </li>
            <li class="nav-item">
                <a
                    @click="$_generalMixin_scrollSimpleBarTabContent({behavior: 'instant', timeout: 250}), updateSelectedTabId('vehicle-health')"
                    class="nav-link"
                    :class="{active: defaultTab === 'vehicle-health'}"
                    id="vehicle-health-tab"
                    data-toggle="tab"
                    href="#vehicle-health"
                    role="tab"
                    aria-controls="vehicle-health"
                    aria-selected="false"
                >
                    Vehicle Health
                    <span
                        class="badge badge-pill badge-alpha_primary"
                    >
                        <i v-if="isLoadingServicesInfo.getWarningsByVin" class="fas fa-circle-notch fa-spin"></i>

                        <span
                            v-else-if="enumerations && enumerations.vehicleWarnings"
                            v-tooltip:top title="No. of new results in the last 30 days"
                        >{{ enumerations.vehicleWarnings }}</span>
                    </span>
                </a>
            </li>
        </ul>

        <button
            @click="$htmlToPaper(selectedTabId)"
            type="button"
            class="btn badge badge-alpha_neutral--shift"
        >
            <i class="icon">print</i>
            Print
        </button>
    </div>
</template>

<script>
import { generalMixin } from '@/mixins/generalMixin';
import { mapState } from 'vuex';

export default {
    mixins: [generalMixin],

    props: {
        defaultTab: {
            type: String,
            default: 'all'
        },

        enumerations: {
            type: Object,
            default: () => ({})
        },

        errors: {
            type: Object,
            default: () => ({}),
        },

        isLoadingServicesInfo: {
            type: Object,
            required: true
        },

        messagesPendingApprovalLength: {
            type: Number,
            default: 0
        },

        // Specify which optional tabs to display
        optionalTabs: {
            type: Array,
            default: () => []
        },

        selectedTabId: {
            type: String,
            required: true
        },

        selectedVehicle: {
            type: Object,
        },
    },

    computed: {
        ...mapState('userSession', {
            userSession: 'userSession',
        }),
    },

    methods: {
        updateSelectedTabId(tabId) {
            this.$emit('updateSelectedTabId', tabId);
        },
    },
}
</script>
