/**
 * Title Manager plugin for Vue.js
 * @author Josh Makar <jmakar@boltontechnology.com>
 * @param {Object} options
 * @param {string} options.faviconNotification - Notification favicon path
 *      if used, the target favicon must have a data attribute of
 *      'data-title-manager-icon' e.g. <link ... data-title-manager-icon>
 */
export default {
    install(Vue, { faviconNotification = null } = {}) {
        class TitleManager {
            #defaultTitle = '';
            #faviconDefault = '';
            #faviconNotification = '';
            #interval = null;
            #newTitle = '';
            #pageActive = true;

            constructor(faviconNotification = null) {
                this.#defaultTitle = document.title;
                this.#faviconDefault = document.querySelector('link[data-title-manager-icon]')?.href ?? '';
                this.#faviconNotification = faviconNotification;
                this.#pageActive = document.visibilityState === 'visible';

                document.addEventListener('visibilitychange', () => {
                    this.#visibilityChange();
                });
            }

            /**
             * The main method for flashing the page title.
             * @param {string} newTitle
             * @param {Object} options
             * @param {int} options.duration - The total duration in seconds
             *      to flash the title.
             * @param {boolean} options.force - Force the title to flash
             *      even if the page is active.
             * @param {int} options.interval - How often the title should
             *      toggle in seconds.
             * @param {boolean} options.setFavicon - Set the favicon to the
             *      notification favicon.
             */
            flash(newTitle, { duration = 10, force = false, interval = 2, setFavicon = true } = {}) {
                if (!force && this.#pageActive) {
                    return;
                }

                this.#newTitle = newTitle;

                this.#updateDocumentTitle(newTitle);

                if (setFavicon) {
                    this.#setFavicon(this.#faviconNotification);
                }

                if (interval > 0) {
                    this.#setFlashInterval(interval);
                }

                if (duration > 0) {
                    this.#setFlashDuration(duration);
                }
            }

            /**
             * Clear the interval and reset the title and favicon.
             */
            clear() {
                this.#clearFlashInterval();

                this.#updateDocumentTitle(this.#defaultTitle);

                this.#setFavicon(this.#faviconDefault);
            }

            /**
             * Check if the favicon can be set.
             * @returns {boolean}
             */
            get #isFaviconSetable() {
                return Boolean(this.#faviconNotification && this.#faviconDefault);
            }

            /**
             * Handle the document visibility change event.
             */
            #visibilityChange() {
                if (document.visibilityState === 'visible') {
                    this.#pageActive = true;

                    this.clear();
                } else {
                    this.#pageActive = false;
                }
            }

            /**
             * Update the document title.
             * @param {string} title
             */
            #updateDocumentTitle(title) {
                document.title = title;
            }

            /**
             * Set the favicon.
             * @param {string} favicon
             */
            #setFavicon(favicon) {
                if (!this.#isFaviconSetable) {
                    return;
                }

                // Find or create the favicon link tag by data attribute 'title-manager-favicon'
                const faviconLink =
                    document.querySelector('link[data-title-manager-icon]') || document.createElement('link');

                faviconLink.rel = 'icon';
                faviconLink.href = favicon;
            }

            /**
             * Set the interval for flashing the title.
             * @param {int} interval
             */
            #setFlashInterval(interval) {
                this.#clearFlashInterval();

                this.#interval = setInterval(() => {
                    this.#updateDocumentTitle(document.title === this.#newTitle ? this.#defaultTitle : this.#newTitle);
                }, interval * 1000);
            }

            #clearFlashInterval() {
                clearInterval(this.#interval);
            }

            /**
             * Set the duration for flashing the title.
             * @param {int} duration
             */
            #setFlashDuration(duration) {
                setTimeout(() => {
                    this.#clearFlashInterval();

                    if (this.#pageActive) {
                        this.#setFavicon(this.#faviconDefault);
                    }

                    this.#updateDocumentTitle(this.#defaultTitle);
                }, duration * 1000);
            }
        }

        Vue.prototype.$titleManager = new TitleManager(faviconNotification);
    },
};
