<template>

    <div class="col p-3 d-flex flex-column flex-grow-1 flex-shrink-1 h-100 overflow-auto">
        <div class="row mb-4">
            <div class="col-12 col-md-4">
                <span class="font-20 font-weight-bolder">Dealership Settings</span>
            </div>

            <div class="col-12 col-md-2">
                <el-select
                    class="select-blue hide-overflow-tags w-100"
                    v-model="settingsCategory"
                    placeholder="Select"
                >
                    <el-option
                        v-for="(settingCategory, index) in settingsCategories"
                        :key="index"
                        :label="settingCategory.label"
                        :value="settingCategory.value"
                    ></el-option>
                </el-select>
            </div>
        </div>

        <!-- If Settings Loaded -->
        <template v-if="!(isLoading.getConfiguredDealershipSettings || isLoading.getAvailableSettingsList || isLoadingGlobal.getASMList) && settings.length">
            <!-- Notifications Settings -->
            <div v-if="settingsCategory === 'notificationSettings'" class="row mb-4">
                <div class="col col-md-6">
                    <div class="card">
                        <h5 class="card-header">Notifications</h5>

                        <div class="card-body p-0">
                            <!-- Show SMS Notifications -->
                            <template v-if="showSmsNotifications">
                                <div class="setting">
                                    <div class="setting__name">
                                        <strong>Show SMS Notifications</strong>
                                        <p>{{ showSmsNotifications.description }}</p>
                                    </div>
                                    <div class="setting__input">
                                        <el-switch
                                            v-model="dealershipSettingsConfig['show-sms-notifications']"
                                            @change="updateConfiguredDealershipSettings('show-sms-notifications')"
                                        ></el-switch>
                                    </div>
                                </div>
                            </template>

                            <!-- Show Case Notifications -->
                            <template v-if="showCaseNotifications">
                                <div class="setting">
                                    <div class="setting__name">
                                        <strong>Show Case Notifications</strong>
                                        <p>{{ showCaseNotifications.description }}</p>
                                    </div>
                                    <div class="setting__input">
                                        <el-switch
                                            v-model="dealershipSettingsConfig['show-case-notifications']"
                                            @change="updateConfiguredDealershipSettings('show-case-notifications')"
                                        ></el-switch>
                                    </div>
                                </div>
                            </template>

                            <!-- Show Payment Message Notifications -->
                            <template v-if="showSmsPaymentNotifications">
                                <div class="setting">
                                    <div class="setting__name">
                                        <strong>Show SMS Payment Notifications</strong>
                                        <p>{{ showSmsPaymentNotifications.description }}</p>
                                    </div>
                                    <div class="setting__input">
                                        <el-switch
                                            v-model="dealershipSettingsConfig['show-sms-payment-notifications']"
                                            @change="updateConfiguredDealershipSettings('show-sms-payment-notifications')"
                                        ></el-switch>
                                    </div>
                                </div>
                            </template>

                            <!-- Show Pending Message Notifications -->
                            <template v-if="showPendingMessageNotifications" >
                                <div class="setting">
                                    <div class="setting__name">
                                        <strong>Show Pending Message Notifications</strong>
                                        <p>{{ showPendingMessageNotifications.description }}</p>
                                    </div>
                                    <div class="setting__input">
                                        <el-switch
                                            v-model="dealershipSettingsConfig['show-pending-message-notifications']"
                                            @change="updateConfiguredDealershipSettings('show-pending-message-notifications')"
                                        ></el-switch>
                                    </div>
                                </div>
                            </template>

                            <!-- Show Chat Notifications -->
                            <template v-if="showChatNotifications">
                                <div class="setting">
                                    <div class="setting__name">
                                        <strong>Show Chat Notifications</strong>
                                        <p>{{ showChatNotifications.description }}</p>
                                    </div>
                                    <div class="setting__input">
                                        <el-switch
                                            v-model="dealershipSettingsConfig['show-chat-notifications']"
                                            @change="updateConfiguredDealershipSettings('show-chat-notifications')"
                                        ></el-switch>
                                    </div>
                                </div>
                            </template>

                            <!-- Show All ASM Notifications -->
                            <template v-if="showAllAsmNotifications" >
                                <div class="setting">
                                    <div class="setting__name">
                                        <strong>Show All ASM Notifications</strong>
                                        <p>{{ showAllAsmNotifications.description }}</p>
                                        <small>Enabling this setting will nullify the "Show Notifications for Specific ASMs" settings.</small>
                                    </div>
                                    <div class="setting__input">
                                        <el-switch
                                            v-model="dealershipSettingsConfig['show-all-asm-notifications']"
                                            @change="updateConfiguredDealershipSettings('show-all-asm-notifications')"
                                        ></el-switch>
                                    </div>
                                </div>
                            </template>

                            <!-- Show Notifications for Specific ASMs -->
                            <template v-if="showNotificationsForSpecificAsm">
                                <div class="setting" :title="dealershipSettingsConfig['show-all-asm-notifications'] ? `Disabled when 'Show All ASM Notifications' is enabled` : ''">
                                    <div class="setting__name" :class="{'text-muted': dealershipSettingsConfig['show-all-asm-notifications']}">
                                        <strong>Show Notifications for Specific ASMs</strong>
                                        <p>{{ showNotificationsForSpecificAsm.description }}</p>
                                    </div>
                                    <div class="setting__input">
                                        <el-select
                                            v-model="dealershipSettingsConfig['show-notifications-for-specific-asm']"
                                            @change="updateConfiguredDealershipSettings('show-notifications-for-specific-asm')"
                                            class="w-100"
                                            placeholder="Select ASMs"
                                            :multiple="true"
                                            :collapse-tags="true"
                                            :disabled="dealershipSettingsConfig['show-all-asm-notifications']"
                                        >
                                            <el-option
                                                v-for="(asm, index) in asms"
                                                :key="index"
                                                :label="`${asm.asmName} (${asm.asmNumber})`"
                                                :value="asm.asmId"
                                            ></el-option>
                                        </el-select>
                                    </div>
                                </div>
                            </template>

                            <!-- Show Push Notifications -->
                            <template v-if="showPushNotifications">
                                <div class="setting">
                                    <div class="setting__name">
                                        <strong>Show Push Notifications</strong>
                                        <p>{{ showPushNotifications.description }}</p>
                                        <small>
                                            Push notifications correspond to the notifications in your smart phone or other smart device's notifications tray.<br>
                                            This setting is only applicable when using the Android or IOS Bolt On Technology® App.
                                        </small>
                                    </div>
                                    <div class="setting__input">
                                        <el-switch
                                            v-model="dealershipSettingsConfig['show-push-notifications']"
                                            @change="updateConfiguredDealershipSettings('show-push-notifications')"
                                        ></el-switch>
                                    </div>
                                </div>
                            </template>

                            <!-- Set Number of Days to Store Notifications -->
                            <template v-if="numberOfDaysToStoreNotifications">
                                <div class="setting">
                                    <div class="setting__name">
                                        <strong>How Long to Save Notifications</strong>
                                        <p>{{ numberOfDaysToStoreNotifications.description }}</p>
                                    </div>
                                    <div class="setting__input">
                                        <el-select
                                            v-model="dealershipSettingsConfig['number-of-days-to-store-notifications']"
                                            @change="updateConfiguredDealershipSettings('number-of-days-to-store-notifications')"
                                            class="w-100"
                                            :placeholder="`Default ${numberOfDaysToStoreNotifications.default_value}`"
                                        >
                                            <el-option
                                                v-for="(settingValue, index) in numberOfDaysToStoreNotifications.allowed_setting_values"
                                                :key="index"
                                                :label="`${settingValue.value} days`"
                                                :value="settingValue.value"
                                            ></el-option>
                                        </el-select>
                                    </div>
                                </div>
                            </template>

                            <!-- Set Notification Reminder Time -->
                            <template v-if="notificationReminderTime">
                                <div class="setting">
                                    <div class="setting__name">
                                        <strong>Notification Reminder Rate</strong>
                                        <p>{{ notificationReminderTime.description }}</p>
                                    </div>
                                    <div class="setting__input">
                                        <el-select
                                            v-model="dealershipSettingsConfig['notification-reminder-time']"
                                            @change="updateConfiguredDealershipSettings('notification-reminder-time')"
                                            class="w-100"
                                            :placeholder="`Default ${notificationReminderTime.default_value}`"
                                        >
                                            <el-option
                                                v-for="(settingValue, index) in notificationReminderTime.allowed_setting_values"
                                                :key="index"
                                                :label="settingValue.caption"
                                                :value="settingValue.value"
                                            ></el-option>
                                        </el-select>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Application Settings -->
            <div v-if="settingsCategory === 'applicationSettings'" class="row">
                <div class="col col-md-6">
                    <div class="card">
                        <h5 class="card-header">Application Settings</h5>

                        <div class="card-body p-0">
                            <!-- Search Results Auto Refresh Interval -->
                            <template v-if="resultsAutoRefreshInterval">
                                <div class="setting">
                                    <div class="setting__name">
                                        <strong>Search Results Auto Refresh Interval</strong>
                                        <p>{{ resultsAutoRefreshInterval.description }}</p>
                                    </div>
                                    <div class="setting__input">
                                        <el-select
                                            v-model="dealershipSettingsConfig['results-auto-refresh-interval']"
                                            @change="updateConfiguredDealershipSettings('results-auto-refresh-interval')"
                                            class="w-100"
                                            :placeholder="`Default ${resultsAutoRefreshInterval.default_value / 60} Min`"
                                        >
                                            <el-option
                                                v-for="(settingValue, index) in resultsAutoRefreshInterval.allowed_setting_values"
                                                :key="index"
                                                :label="`${settingValue.caption}`"
                                                :value="settingValue.value"
                                            ></el-option>
                                        </el-select>
                                    </div>
                                </div>
                            </template>

                            <!-- Default Precomposed SMS Message -->
                            <template v-if="defaultPrecomposedMessage">
                                <div class="setting">
                                    <div class="setting__name">
                                        <strong>Default SMS Message Text</strong>
                                        <p>{{ defaultPrecomposedMessage.description }}</p>
                                        <small>If no value is set, the dealership name will be used.</small>
                                    </div>
                                    <div class="setting__input">
                                        <el-input
                                            v-model="dealershipSettingsConfig['default-precomposed-message']"
                                            @change="updateConfiguredDealershipSettings('default-precomposed-message')"
                                            class="w-100"
                                            placeholder="Auto Dealership:"
                                            maxlength="35"
                                            show-word-limit
                                        ></el-input>
                                    </div>
                                </div>
                            </template>

                            <!-- Enable Customer Messaging -->
                            <template v-if="enableCustomerMessaging">
                                <div class="setting">
                                    <div class="setting__name">
                                        <strong>Enable Customer Messaging</strong>
                                        <p>{{ enableCustomerMessaging.description }}</p>
                                        <small>
                                            If not enabled, only the option to save messages as pending will be available.
                                        </small>
                                    </div>
                                    <div class="setting__input">
                                        <el-switch
                                            v-model="dealershipSettingsConfig['enable-customer-messaging']"
                                            @change="updateConfiguredDealershipSettings('enable-customer-messaging')"
                                        ></el-switch>
                                    </div>
                                </div>
                            </template>

                            <!-- Enabled Experimental Features -->
                            <template v-if="enabledExperimentalFeatures && enabledExperimentalFeatures.allowed_setting_values.length">
                                <div class="setting">
                                    <div class="setting__name">
                                        <strong>Enabled Experimental Features</strong>
                                        <p>{{ enabledExperimentalFeatures.description }}</p>
                                    </div>
                                    <div class="setting__input">
                                        <el-select
                                            v-model="dealershipSettingsConfig['enabled-experimental-features']"
                                            @change="updateConfiguredDealershipSettings('enabled-experimental-features')"
                                            class="w-100"
                                            placeholder="Select Experimental Features"
                                            :multiple="true"
                                            :collapse-tags="true"
                                        >
                                            <el-option
                                                v-for="(settingValue, index) in enabledExperimentalFeatures.allowed_setting_values"
                                                :key="index"
                                                :label="settingValue.caption"
                                                :value="settingValue.value"
                                            ></el-option>
                                        </el-select>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <!-- Else if settings not loaded -->
        <template v-else-if="isLoading.getConfiguredDealershipSettings || isLoading.getAvailableSettingsList || isLoadingGlobal.getASMList">
            Loading Settings...
        </template>

        <!-- Else -->
        <template v-else>
            Unable to load settings
        </template>
    </div>

</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    components: {
        //
    },

    data() {
        return {
            isLoading: {
                getAvailableSettingsList: false,
                getConfiguredDealershipSettings: false,
                updateConfiguredDealershipSettings: false,
            },

            // Available settings to choose from
            settings: [],

            // Category of settings
            settingsCategories: [
                { value: 'notificationSettings',    label: 'Notifications' },
                { value: 'applicationSettings',     label: 'Application' },
            ],

            // Settings category to display
            settingsCategory: 'notificationSettings',

            // Settings to update
            settingsToBeUpdated: [],

            // Variable to hold timeout ID for preventing settings update spam
            updateConfiguredDealershipSettingsTimeout: null,

            // Settings the user currently has configured
            dealershipSettings: [],

            // Settings to pass when updating the user' settings
            dealershipSettingsConfig: {
                'default-precomposed-message': null,
                'enable-customer-messaging': null,
                'enabled-experimental-features': [],
                'notification-reminder-time': null,
                'number-of-days-to-store-notifications': null,
                'results-auto-refresh-interval': null,
                'show-all-asm-notifications': null,
                'show-case-notifications': null,
                'show-chat-notifications': null,
                'show-notifications-for-specific-asm': [],
                'show-pending-message-notifications': null,
                'show-push-notifications': null,
                'show-sms-notifications': null,
                'show-sms-payment-notifications': null,
            },
        }
    },

    computed: {
        ...mapState('global', {
            isLoadingGlobal: 'isLoading',
        }),

        ...mapState('global', {
            asms: 'asms',
        }),

        defaultPrecomposedMessage() {
            return this.settings.find(setting => setting.name === 'default-precomposed-message');
        },

        enableCustomerMessaging() {
            return this.settings.find(setting => setting.name === 'enable-customer-messaging');
        },

        enabledExperimentalFeatures() {
            return this.settings.find(setting => setting.name === 'enabled-experimental-features');
        },

        notificationReminderTime() {
            return this.settings.find(setting => setting.name === 'notification-reminder-time');
        },

        numberOfDaysToStoreNotifications() {
            return this.settings.find(setting => setting.name === 'number-of-days-to-store-notifications');
        },

        resultsAutoRefreshInterval() {
            return this.settings.find(setting => setting.name === 'results-auto-refresh-interval');
        },

        showAllAsmNotifications() {
            return this.settings.find(setting => setting.name === 'show-all-asm-notifications');
        },

        showCaseNotifications() {
            return this.settings.find(setting => setting.name === 'show-case-notifications');
        },

        showChatNotifications() {
            return this.settings.find(setting => setting.name === 'show-chat-notifications');
        },

        showNotificationsForSpecificAsm() {
            return this.settings.find(setting => setting.name === 'show-notifications-for-specific-asm');
        },

        showPendingMessageNotifications() {
            return this.settings.find(setting => setting.name === 'show-pending-message-notifications');
        },

        showPushNotifications() {
            return this.settings.find(setting => setting.name === 'show-push-notifications');
        },

        showSmsNotifications() {
            return this.settings.find(setting => setting.name === 'show-sms-notifications');
        },

        showSmsPaymentNotifications() {
            return this.settings.find(setting => setting.name === 'show-sms-payment-notifications');
        },
    },

    watch: {
        //
    },

    created() {
        // Get ASM list if empty
        if (!this.asms.length) {
            this.getASMList(this.selectedDealerId);
        }

        // Get Settings
        this.getConfiguredDealershipSettings();
        this.getAvailableSettingsList();
    },

    mounted() {
        //
    },

    methods : {
        // Vuex services actions
        ...mapActions('global', {
            getASMList: 'getASMList',
        }),

        // Vuex userSession actions
        ...mapActions('userSession', {
            getUserSession: 'getUserSession',
        }),

        /**
         * Get Available Settings List
         */
        getAvailableSettingsList() {
            this.isLoading.getAvailableSettingsList = true;

            axios
                .get(route('api.dealership-settings.getSettingsList', { selectedDealer: this.selectedDealerId }))
                .then(response => {
                    this.settings = response.data;

                    this.configureSettingsDefaults();
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    this.isLoading.getAvailableSettingsList = false;
                });
        },

        /**
         * Set the default settings
         */
        configureSettingsDefaults() {
            if (this.dealershipSettingsConfig['default-precomposed-message'] === null) {
                this.dealershipSettingsConfig['default-precomposed-message'] = this.settings.find(setting => setting.name === 'default-precomposed-message').default_value;
            }

            if (this.dealershipSettingsConfig['enable-customer-messaging'] === null) {
                let setting = this.settings.find(setting => setting.name === 'enable-customer-messaging');
                let defaultValue = setting && setting.default_value ? true : false;
                this.dealershipSettingsConfig['enable-customer-messaging'] = defaultValue;
            }

            if (this.dealershipSettingsConfig['enabled-experimental-features'] === null) {
                let setting = this.settings.find(setting => setting.name === 'enabled-experimental-features');
                let defaultValue = setting && setting.default_value ? true : false;
                this.dealershipSettingsConfig['enabled-experimental-features'] = defaultValue;
            }

            if (this.dealershipSettingsConfig['notification-reminder-time'] === null) {
                this.dealershipSettingsConfig['notification-reminder-time'] = this.settings.find(setting => setting.name === 'notification-reminder-time').default_value;
            }

            if (this.dealershipSettingsConfig['number-of-days-to-store-notifications'] === null) {
                this.dealershipSettingsConfig['number-of-days-to-store-notifications'] = this.settings.find(setting => setting.name === 'number-of-days-to-store-notifications').default_value;
            }

            if (this.dealershipSettingsConfig['results-auto-refresh-interval'] === null) {
                this.dealershipSettingsConfig['results-auto-refresh-interval'] = this.settings.find(setting => setting.name === 'results-auto-refresh-interval').default_value;
            }

            if (this.dealershipSettingsConfig['show-all-asm-notifications'] === null) {
                let setting = this.settings.find(setting => setting.name === 'show-all-asm-notifications');
                let defaultValue = setting && setting.default_value ? true : false;
                this.dealershipSettingsConfig['show-all-asm-notifications'] = defaultValue;
            }

            if (this.dealershipSettingsConfig['show-case-notifications'] === null) {
                let setting = this.settings.find(setting => setting.name === 'show-case-notifications');
                let defaultValue = setting && setting.default_value ? true : false;
                this.dealershipSettingsConfig['show-case-notifications'] = defaultValue;
            }

            if (this.dealershipSettingsConfig['show-chat-notifications'] === null) {
                let setting = this.settings.find(setting => setting.name === 'show-chat-notifications');
                let defaultValue = setting && setting.default_value ? true : false;
                this.dealershipSettingsConfig['show-chat-notifications'] = defaultValue;
            }

            if (this.dealershipSettingsConfig['show-pending-message-notifications'] === null) {
                let setting = this.settings.find(setting => setting.name === 'show-pending-message-notifications');
                let defaultValue = setting && setting.default_value ? true : false;
                this.dealershipSettingsConfig['show-pending-message-notifications'] = defaultValue;
            }

            if (this.dealershipSettingsConfig['show-push-notifications'] === null) {
                let setting = this.settings.find(setting => setting.name === 'show-push-notifications');
                this.dealershipSettingsConfig['show-push-notifications'] = !!(setting && setting.default_value);
            }

            if (this.dealershipSettingsConfig['show-sms-notifications'] === null) {
                let setting = this.settings.find(setting => setting.name === 'show-sms-notifications');
                let defaultValue = setting && setting.default_value ? true : false;
                this.dealershipSettingsConfig['show-sms-notifications'] = defaultValue;
            }

            if (this.dealershipSettingsConfig['show-sms-payment-notifications'] === null) {
                let setting = this.settings.find(setting => setting.name === 'show-sms-payment-notifications');
                let defaultValue = setting && setting.default_value ? true : false;
                this.dealershipSettingsConfig['show-sms-payment-notifications'] = defaultValue;
            }
        },

        /**
         * Get Configured Dealership Settings
         */
        getConfiguredDealershipSettings() {
            this.isLoading.getConfiguredDealershipSettings = true;

            axios
                .get(route('api.dealership-settings.getConfiguredSettings', { selectedDealer: this.selectedDealerId }))
                .then(response => {
                    this.dealershipSettings = response.data;

                    this.updateUserInterfaceSettings();
                })
                .catch(error => {
                    if (error.response?.data?.errors) {
                        console.error(error.response.data.errors);
                    } else {
                        console.error(error);
                    }
                })
                .finally(() => {
                    this.isLoading.getConfiguredDealershipSettings = false;
                });
        },

        /**
         * Update Configured Dealership Settings
         */
        updateConfiguredDealershipSettings(settingToBeUpdated) {
            // Cancel previous timeout
            clearTimeout(this.updateConfiguredDealershipSettingsTimeout);

            // Add setting to be updated
            if (settingToBeUpdated && !this.settingsToBeUpdated.find(setting => setting === settingToBeUpdated)) {
                this.settingsToBeUpdated.push(settingToBeUpdated);
            }

            // Set timeout to prevent rapid settings updates
            this.updateConfiguredDealershipSettingsTimeout = setTimeout(() => {
                this.isLoading.updateConfiguredDealershipSettings = true;

                // Settings to update
                const dealershipSettings = {};
                this.settingsToBeUpdated.forEach(setting => {
                    dealershipSettings[setting] = this.dealershipSettingsConfig[setting];
                });

                axios
                    .post(route('api.dealership-settings.updateConfiguredSettings', { selectedDealer: this.selectedDealerId }), dealershipSettings)
                    .then(response => {
                        this.$message({
                            type: 'success',
                            message: 'Settings Updated'
                        });

                        this.getUserSession(this.selectedDealerId);
                    })
                    .catch(error => {
                        this.$message({
                            type: 'error',
                            message: 'One or more settings were not updated'
                        });

                        if (error.response?.data?.dealershipSettings) {
                            this.dealershipSettings = error.response.data.dealershipSettings;

                            this.updateUserInterfaceSettings();
                        }

                        if (error.response?.data?.errors) {
                            console.error(error.response.data.errors);
                        } else {
                            console.error(error);
                        }
                    })
                    .finally(() => {
                        this.settingsToBeUpdated = [];
                        this.isLoading.updateConfiguredDealershipSettings = false;
                    });
            }, 2500);
        },

        /**
         * Update the User Interface settings
         */
        updateUserInterfaceSettings() {
            const defaultPrecomposedMessage = this.dealershipSettings.find(setting => setting.setting.name === 'default-precomposed-message');
            const enableCustomerMessaging = this.dealershipSettings.find(setting => setting.setting.name === 'enable-customer-messaging');
            const enabledExperimentalFeatures = this.dealershipSettings.filter(setting => setting.setting.name === 'enabled-experimental-features');
            const notificationReminderTime = this.dealershipSettings.find(setting => setting.setting.name === 'notification-reminder-time');
            const numberOfDaysToStoreNotifications = this.dealershipSettings.find(setting => setting.setting.name === 'number-of-days-to-store-notifications');
            const showAllAsmNotifications = this.dealershipSettings.find(setting => setting.setting.name === 'show-all-asm-notifications');
            const showCaseNotifications = this.dealershipSettings.find(setting => setting.setting.name === 'show-case-notifications');
            const showChatNotifications = this.dealershipSettings.find(setting => setting.setting.name === 'show-chat-notifications');
            const showNotificationsForSpecificAsm = this.dealershipSettings.filter(setting => setting.setting.name === 'show-notifications-for-specific-asm');
            const showPendingMessageNotifications = this.dealershipSettings.find(setting => setting.setting.name === 'show-pending-message-notifications');
            const showSmsNotifications = this.dealershipSettings.find(setting => setting.setting.name === 'show-sms-notifications');
            const showSmsPaymentNotifications = this.dealershipSettings.find(setting => setting.setting.name === 'show-sms-payment-notifications');
            const showPushNotifications = this.dealershipSettings.find(setting => setting.setting.name === 'show-push-notifications');
            const resultsAutoRefreshInterval = this.dealershipSettings.find(setting => setting.setting.name === 'results-auto-refresh-interval');

            if (defaultPrecomposedMessage) {
                this.dealershipSettingsConfig['default-precomposed-message'] = defaultPrecomposedMessage.unconstrained_value;
            }

            if (enableCustomerMessaging) {
                this.dealershipSettingsConfig['enable-customer-messaging'] = enableCustomerMessaging.boolean_value ? true : false;
            }

            if (enabledExperimentalFeatures.length) {
                enabledExperimentalFeatures.forEach(setting => {
                    this.dealershipSettingsConfig['enabled-experimental-features'].push(setting.allowed_setting_value.value);
                });
            }

            if (notificationReminderTime) {
                this.dealershipSettingsConfig['notification-reminder-time'] = notificationReminderTime.allowed_setting_value.value;
            }

            if (numberOfDaysToStoreNotifications) {
                this.dealershipSettingsConfig['number-of-days-to-store-notifications'] = numberOfDaysToStoreNotifications.allowed_setting_value.value;
            }

            if (resultsAutoRefreshInterval) {
                this.dealershipSettingsConfig['results-auto-refresh-interval'] = resultsAutoRefreshInterval.allowed_setting_value.value;
            }

            if (showAllAsmNotifications) {
                this.dealershipSettingsConfig['show-all-asm-notifications'] = showAllAsmNotifications.boolean_value ? true : false;
            }

            if (showCaseNotifications) {
                this.dealershipSettingsConfig['show-case-notifications'] = showCaseNotifications.boolean_value ? true : false;
            }

            if (showChatNotifications) {
                this.dealershipSettingsConfig['show-chat-notifications'] = showChatNotifications.boolean_value ? true : false;
            }

            if (showNotificationsForSpecificAsm.length) {
                showNotificationsForSpecificAsm.forEach(setting => {
                    this.dealershipSettingsConfig['show-notifications-for-specific-asm'].push(setting.unconstrained_value);
                });
            }

            if (showPendingMessageNotifications) {
                this.dealershipSettingsConfig['show-pending-message-notifications'] = showPendingMessageNotifications.boolean_value ? true : false;
            }

            if (showPushNotifications) {
                this.dealershipSettingsConfig['show-push-notifications'] = !!showPushNotifications.boolean_value;
            }

            if (showSmsNotifications) {
                this.dealershipSettingsConfig['show-sms-notifications'] = showSmsNotifications.boolean_value ? true : false;
            }

            if (showSmsPaymentNotifications) {
                this.dealershipSettingsConfig['show-sms-payment-notifications'] = showSmsPaymentNotifications.boolean_value ? true : false;
            }
        },
    },
}

</script>

<style lang="scss" scoped>
.setting {
    border: solid grey;
    border-width: 0 0 1px;
    display: flex;
    justify-content: space-between;

    &:last-child {
        border-width: 0;
    }

    .setting__name, .setting__input {
        padding: 1.25rem;
    }

    .setting__input {
        align-self: center;
        display: flex;
        flex-basis: 50%;
        justify-content: flex-end;
    }
}
</style>
